import { defineStore } from 'pinia'
import { LoadRoles } from '@/core/var/user'

export const useAuthStore = defineStore({
    id: 'authStore',
    state() {
        return {
            user_id: false,
            loggedIn:false,
            userdata:null,
            user_role:0,
            roles:{}
        };
    },
    actions: {
        setAuth(userdata){
            this.loggedIn = true;
            this.setUserData(userdata);
        },
        Logout() {
            this.loggedIn = false;
            this.userdata = null;
        },
        setUserData(userdata){

            this.userdata  = userdata;
            if (userdata.user_roles.length !== 0){
                this.setUserRole(userdata.user_roles[0]);
            }
        },
        setUserRole(user_role){
            this.user_role = user_role;
        },
        getAuth(component_permission) {
            if (this.loggedIn === true && this.user_role <= component_permission) {
                return true;
            } else {
                return false;
            }
        },
        async loadRoles(){

            try {
               this.roles =  await LoadRoles();

            } catch (error) {
                console.log(error)
                return error
            }

        }
    },
    getters: {
        getUserData(state){
            return state.userdata;
        },
        isloggedIn(state) {
            return state.loggedIn;
        },
        getUserRole(state) {
            return state.user_role;
        },
        getRoles(state){
            return state.roles;
        }
    }
});