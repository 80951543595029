const Message = (message = false, mode = false, state) => {
 
        state.message.show = true;
        state.message.messagemode = mode;
        state.message.message = message;

        window.setTimeout(() => {
            state.message.show = false;
        }, 3000);
}
export {Message}

const ErrorLog = (error) => {
    const IN_PRODUCTION = process.env.NODE_ENV;
    if (!IN_PRODUCTION) {
        if (error.response) {
            console.log(error.response.data)
            console.log(error.response.status)
            console.log(error.response.headers)

        } else if (error.request) {
            console.log(error.request)
        } else {
            console.log("Error", error.message)
        }
    }
}
export { ErrorLog }

