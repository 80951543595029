<template>
    <div>Aktuelle Rolle {{ userRole }}</div>
    <div v-if="userRole <= 1">User</div>
    <div v-if="userRole == 0">Admin</div>
    <div v-if="userRole <= 2">Finanzen</div>
</template>
<script setup>
import { ref } from 'vue'
import { useAuthStore } from '@/core/store/AuthStore';
const auth = useAuthStore();
const userRole = ref(auth.getUserRole)
// console.log(auth.getUserRole)
</script>