<template>
    <FormUserProfile></FormUserProfile>
    <Divider />
    <FormUserPassword></FormUserPassword>
    <Divider />
    <form class="space-y-8 divide-y divide-gray-200">
        <div>
            <h3 class="text-lg font-medium leading-6 text-gray-900">Dashboard-Einstellungen zurücksetzen</h3>
            <p class="mt-1 max-w-2xl text-sm text-gray-500">Stellen Sie hier alle Dashboard-Einstellungen wie Fenster oder Popups mit einem Klick zurück.</p>
        </div>

        <div class="pt-5">
            <div class="flex justify-end">
                <button @click="removeItems" class="ml-3 inline-flex justify-center rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 disabled:bg-gray-100">
                    Zurücksetzen
                </button>
            </div>
        </div>
    </form>
</template>

<script setup>
import FormUserProfile from '../Forms/Form-UserProfile.vue';
import FormUserPassword from '../Forms/Form-UserPassword.vue';
import Divider from '../UI/Main-divider.vue';

const removeItems = () =>{
    localStorage.removeItem('sidebarStatus');
    localStorage.removeItem('closed_infobox');
}
</script>
