<template>
    <button type="button" @click="buttonClicked" :class="buttonStyles.button" class="inline-flex items-center rounded-md border border-transparent font-medium  shadow-sm ">
        <component :is="icon" class="-ml-0.5" :class="buttonStyles.icon" aria-hidden="true" />
        <slot />
    </button>
</template>

<script setup>
import { defineProps, ref, computed, defineEmits } from 'vue';
import clsx from 'clsx';


const props = defineProps(['icon', 'size', 'color', 'textcolor']);
const icon = ref(props.icon);
const size = ref(props.size);
const color = ref(props.color);
const textcolor = ref(props.textcolor);
const emit = defineEmits(['clicked']);

const buttonClicked = ()  => {
    return emit('clicked', true);
}

const buttonSize = () =>{
    return sizes[size.value];
};

const colors = val => {
    /*
    @ for Tailwind JIT Compiler
    bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 text-blue-500
    bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 text-gray-500
    bg-red-600 hover:bg-red-700 focus:ring-red-500 text-red-500
    bg-green-600 hover:bg-green-700 focus:ring-green-500 text-green-500
    bg-yellow-600 hover:bg-yellow-700 focus:ring-yellow-500 text-yellow500
    bg-purple-600 hover:bg-purple-700 focus:ring-purple-500 text-purple-500
    */
    const c = clsx(['bg-'+val+'-600', 'hover:bg-' + val + '-700', 'focus:ring-' + val + '-500']);

    return c;
}

const textColor = () =>{
    return textcolor.value != undefined ? `text-${textcolor.value}-500` : 'text-white';
}

const buttonColor = val => {
    const col = colors(val);
    return col;
};

const buttonStyles = computed(() =>{
    const res = {button: `${clsx(buttonSize()[0])} ${buttonColor(color.value)} ${textColor()}`, icon: clsx(buttonSize()[1]) };

    return res;
});


const sizes = {
    sm: [['px-3', 'py-2','leading-4', 'text-sm'], ['h-4', 'w-4' ,'mr-2']],
    md: [['px-4', 'py-2', 'text-sm'], ['h-5', 'w-5', 'mr-2']],
    lg: [['px-3', 'py-2', 'text-base'], ['h-5', 'w-5', 'mr-3']],
    xl: [['px-6', 'py-3', 'text-lg'], ['h-5', 'w-5', 'mr-3']],
}



</script>