<script setup>
import { reactive, ref, inject, onMounted } from 'vue'
import LoadingSpinner from './UI/Loading-Spinner.vue';
import MainToast from './UI/Main-Toast.vue';
import CoreRouter from '../router/router'
import axios from 'axios';
import { parseJwt } from '@/core/var/jwt';
import { useCustomerStore } from '@/core/store/CustomerStore';
import { useAuthStore } from '@/core/store/AuthStore';


const getQueryParam = key => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return urlSearchParams.get(key);
}

const token = ref(false); 
const param2 = ref(getQueryParam('param2')); 

const keep_logged_in = ref(true);
const customerStore = useCustomerStore();
const appmode = customerStore.getCustomer;


onMounted(() =>{
    const tkn = getQueryParam('token');
    console.log(tkn);
    token.value = tkn;
});

const getLogo = () => {
    return require(`../../customers/${appmode.customer}/assets/${appmode.assets.logo}`);
}
</script>

<template>
     <div class="bg-white">
      <img src="../../customers/itmc/assets/images/color.png" />
        <main class="mx-auto w-full max-w-7xl px-6 pb-16 pt-10 sm:pb-24 lg:px-8">
          <img class="mx-auto h-10 w-auto sm:h-12  " :src="getLogo()" alt="" />
          <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">A Neuraxis 2 Page</h2>
          <div class="mx-auto mt-20 max-w-2xl text-center sm:mt-24">

            <div v-if="!token">
            <p class="text-base font-semibold leading-8 text-primary-600">404</p>
            <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Diese Seite existiert nicht</h1>
            <p class="mt-4 text-base leading-7 text-gray-600 sm:mt-6 sm:text-lg sm:leading-8">Entschuldigung, wir konnten die Seite leider nicht finden.</p>
            </div>
            <div v-else>
                Hier könnte was stehen: {{ token}}
            </div>
        
        </div>
          <div class="mx-auto mt-16 flow-root max-w-lg sm:mt-20">
            <h2 class="sr-only">Hallo Welt</h2>

            <div class="mt-10 flex justify-center">
              <router-link to="/" class="text-sm font-semibold leading-6 text-primary-600">
                <span aria-hidden="true">&larr;</span>
                Zurück
              </router-link>
            </div>
          </div>
        </main>
      </div>
</template>