<script setup>
import { ref, onMounted, useSSRContext } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import { EnumStringBody } from '@babel/types'
/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
// filters
const filter_documents = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
//////////////////
// Data Sources //
//////////////////

const workspaces = ref([])
const workspace = ref(null)
const workspace_edit = ref({})
const workspace_current = ref(null)
const workspace_documents = ref([])
const snappy_chats = ref([])
const snappy_chat = ref(null)
const snappy_chat_name = ref(null)
const snappy_chat_id = ref(null)
const snappy_prompts = ref([])
const sources = ref([])
const website_url = ref(null)
const users = ref([])
const users_select = ref([])

const new_workspace_modal = ref(false)
const edit_workspace_modal = ref(false)
const edit_snappy_chat_name_modal = ref(false)
const view_sources_slider = ref(false)
const new_workspace_name = ref("")
const new_workspace_users = ref([])
const new_workspace_owner = ref(null)
const view_documents_slider = ref(false)
const url_download_modal = ref(false)

const delete_workspace_check = ref(false)

const text_prompt = ref(null)
const online_switch = ref(false)

const how_it_works = ref(false)
const roadmap = ref(false)
const updates_roadmap = ref([
    {"name": "Allgemeine Optimierungen", "estimate": "Q2 2024", "details": "Allgemeine und zentrale Funktionen für den LLM und die Snappy-Schnittstelle. Weitere Tests für mehr Stabilität und Leistung."},
    {"name": "Re-Design", "estimate": "Q2 2024", "details": "Neues Snappy Design für die Snappy-LLM. Verbesserte Benutzerfreundlichkeit und Zugänglichkeit."},
    {"name": "Implementierung DMS", "estimate": "Q2 2024", "details": "Integration eines Dokumentenmanagementsystems für die Verwaltung von Dokumenten und Dateien. Automatische Strukturierung (Kategorisierung) und Erstellung von Meta-Beschreibungen (Verschlagwortung / Tags), die direkt vom LLM unterstützt werden. Lichtschnelle Volltextsuche und Filterung in Echtzeit!"},
    {"name": "Advanced DMS", "estimate": "Q3 2024", "details": "Erweiterte Funktionen für das Dokumentenmanagementsystem. Erweiterung eines macOS- und Windows-Desktop-Clients mit nativem Checkout und Prüfung von Dokumenten und Versionierung von Dateien. Vollständig integriert in die Snappy Workflow Engine, ermöglicht Archivierung, Bearbeitung und Zusammenarbeit mit nur einem einzigen Mausklick"},
    {"name": "Vollständige Automatisierung", "estimate": "Q3 2024", "details": "Die Einrichtung der privaten Cloud-Infrastruktur eines Kunden mit allen Funktionen erfolgt vollautomatisch. Die Installation einer On-Premise-Version kann vom unternehmenseigenen IT-Team durchgeführt werden. Mit einfachen Deployment-Skripten kann sie sofort in eine OpenStack-Umgebung integriert werden, ohne dass Support oder exzessive Dokumentation erforderlich sind. "}
])

const llms = ref([
    {name: "Llama3 - 8 Billion (instruct)", slug: "llama3:8b-instruct-q8_0"}
])

onMounted( async () => {
    snappy_chat_id.value = localStorage.getItem('snappy_chat') || null
    workspace.value = localStorage.getItem('workspace') || null
    get_users()
    get_workspaces()
    if (workspace.value != null) {
        get_snappy_chats()
    }
})

const get_workspaces = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/llm/get-workspaces')
    .then(response => {
        workspaces.value = response.data
        console.log("Workspaces: ", workspaces.value)
        if (workspaces.value.length == 0) {
            reset_workspace()
        }
        else
        {
            if (workspace.value == null) {
                workspace.value = workspaces.value[0].slug
                localStorage.setItem('workspace', workspace.value)
                get_workspace()
            }
            else
            {
                get_workspace()
            }
        }
        loader.value = false
    })
}

const get_users = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/llm/get-users')
    .then(response => {
        users.value = response.data
        for (let i = 0; i < users.value.length; i++) {
            if (users.value[i].owner == true) {
                new_workspace_owner.value = users.value[i].email
            }
            else
            {
                users_select.value.push(users.value[i])
            }
        }
        loader.value = false
    })
}

const get_snappy_chats = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/llm/get-snappy-chats', {params: {workspace: workspace.value}})
    .then(response => {
        console.log(response.data)
        snappy_chats.value = response.data
        loader.value = false
        if (snappy_chat_id.value == null && snappy_chats.value.length > 0) {
            snappy_chat_id.value = snappy_chats.value[0].id
            localStorage.setItem('snappy_chat', snappy_chat_id.value)
        }
        else
        {
            localStorage.removeItem('snappy_chat')
        }
        for (let i = 0; i < snappy_chats.value.length; i++) {
            if (snappy_chats.value[i].id == snappy_chat_id.value) {
                snappy_prompts.value = snappy_chats.value[i].chats
                snappy_chat.value = snappy_chats.value[i]
                loader.value = false
                console.log("Selected Chat: " + snappy_chats.value[i].name)
                console.log("Selected Prompts: ", snappy_prompts.value)
            }
        }
    })
}

const new_workspace = () => {
    loader.value = true
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/llm/new-workspace', {name: new_workspace_name.value, users: new_workspace_users.value, owner: new_workspace_owner.value})
    .then(response => {
        console.log(response.data)
        get_workspaces()
        new_workspace_modal.value = false
        new_workspace_name.value = ""
        loader.value = false
    })
}

const get_workspace = () => {
    loader.value = true
    localStorage.setItem('workspace', workspace.value)
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/llm/get-workspace', {params: {workspace: workspace.value}})
    .then(response => {
        console.log("get_workspace", response.data)
        workspace_edit.value = response.data.workspace
        if (workspace_edit.value != null) {
            workspace_current.value = response.data.workspace
            workspace_documents.value = response.data.workspace.documents
            console.log("Workspace Documents: ", workspace_documents.value)
            get_snappy_chats()
        }
        else
        {
            workspace.value = null
            reset_workspace()
        }
        loader.value = false
    })
}

const reset_workspace = () => {
    snappy_chat_id.value = null
    snappy_chats.value = []
    snappy_prompts.value = []
    snappy_chat.value = null
    localStorage.removeItem('snappy_chat')
    if(workspace.value == null) {
        localStorage.removeItem('workspace')
    }
}

const initiate_workspace = () => {
    snappy_chat_id.value = null
    snappy_chats.value = []
    snappy_prompts.value = []
    snappy_chat.value = null
    get_workspace()
}

const edit_workspace = () => {
    workspace_edit.value = workspace_current.value
    console.log("edit_workspace: ", workspace_edit.value)
    edit_workspace_modal.value = true
}

const update_workspace = () => {
    loader.value = true
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/llm/update-workspace', {workspace: workspace.value, name: workspace_edit.value.name, users: workspace_edit.value.users, owner: workspace_edit.value.owner})
    .then(response => {
        console.log(response.data)
        get_workspaces()
        edit_workspace_modal.value = false
        loader.value = false
    })
}

const delete_workspace = () => {
    confirm.require({
        message: 'Bist du sicher, dass du diese Umgebung löschen möchtest? Alle Daten werden unwiderruflich gelöscht!',
        header: 'Bestätigung erforderlich',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, löschen',
        acceptClass: 'p-button-danger',
        rejectLabel: 'Nein',
        accept: () => {
            loader.value = true
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/llm/delete-workspace', {workspace: workspace.value})
            .then(response => {
                console.log(response.data)
                workspace.value = null
                reset_workspace()
                get_workspaces()
                edit_workspace_modal.value = false
                loader.value = false
            })
        }
    })
}

const send_chat = () => {
    loader.value = true
    let mode = (online_switch.value ? "chat" : "query")
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/llm/chat', {workspace: workspace.value, message: text_prompt.value, mode: mode, chat: snappy_chat_id.value})
    .then(response => {
        console.log(response.data)
        loader.value = false
        get_snappy_chats()
    })
}

const new_chat = () => {
    snappy_chat_id.value = null
    snappy_prompts.value = []
    snappy_chat.value = null
    localStorage.removeItem('snappy_chat')
}

const select_snappy_chat = (chat_id) => {
    loader.value = true
    snappy_chat_id.value = chat_id
    console.log("Selecting Chat: " + chat_id)
    localStorage.setItem('snappy_chat', snappy_chat_id.value)
    get_snappy_chats()
}

const remove_snappy_chat = (chat_id) => {
    confirm.require({
        message: 'Bist du sicher, dass du diesen Chat löschen möchtest? Alle Daten werden unwiderruflich gelöscht!',
        header: 'Bestätigung erforderlich',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, löschen',
        acceptClass: 'p-button-danger',
        rejectLabel: 'Nein',
        accept: () => {
            loader.value = true
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/llm/remove-snappy-chat', {workspace: workspace.value, chat: chat_id})
            .then(response => {
                console.log(response.data)
                toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Chat wurde erfolgreich gelöscht', life: 3000});
                if (snappy_chat_id.value == chat_id) {
                    reset_workspace()
                }
                get_snappy_chats()
                loader.value = false
            })
        }
    })
}

const remove_snappy_prompt = (prompt_id) => {
    confirm.require({
        message: 'Bist du sicher, dass du diesen Prompt löschen möchtest? Alle Daten werden unwiderruflich gelöscht!',
        header: 'Bestätigung erforderlich',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, löschen',
        acceptClass: 'p-button-danger',
        rejectLabel: 'Nein',
        accept: () => {
            loader.value = true
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/llm/remove-snappy-prompt', {workspace: workspace.value, chat: snappy_chat_id.value, id: prompt_id})
            .then(response => {
                console.log(response.data)
                toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Prompt wurde erfolgreich gelöscht', life: 3000});
                get_snappy_chats()
                loader.value = false
            })
        }
    })
}

const download_url = () => {
    loader.value = true
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/llm/upload-link', {workspace: workspace.value, chat: snappy_chat_id.value, link: website_url.value})
    .then(response => {
        console.log(response.data)
        toast.add({severity:'success', summary: 'Erfolgreich', detail: 'URL wurde erfolgreich heruntergeladen', life: 3000});
        get_snappy_chats()
        url_download_modal.value = false
        loader.value = false
    })
}

const embed_document = (document) => {
    loader.value = true
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/llm/embed-document-into-llm', {workspace: workspace.value, chat: snappy_chat_id.value, document: document})
    .then(response => {
        console.log(response.data)
        toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Dokument wurde erfolgreich in den LLM eingebettet', life: 3000});
        get_snappy_chats()
        loader.value = false
    })
}

const remove_document = (document) => {
    loader.value = true
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/llm/remove-embed-document', {workspace: workspace.value, docname: document})
    .then(response => {
        console.log(response.data)
        toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Datei wurde erfolgreich aus dem LLM gelöscht', life: 3000});
        get_workspace()
        loader.value = false
    })
}

const edit_snappy_name = () => {
    loader.value = true
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/llm/edit-snappy-chat-name', {workspace: workspace.value, chat: snappy_chat_id.value, name: snappy_chat_name.value})
    .then(response => {
        console.log(response.data)
        toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Chat-Name wurde erfolgreich geändert', life: 3000});
        get_snappy_chats()
        loader.value = false
    })
}

const copy_to_clipboard = (value) => {
    navigator.clipboard.writeText(value)
    toast.add({severity:'success', summary: 'Erfolgreich', detail: 'In Zwischenablage kopiert', life: 3000});
}

const view_sources = (source) => {
    console.log(source)
    sources.value = source
    view_sources_slider.value = true
}

const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}

const format_swiss_date_time = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4) + ' ' + value.substring(11,16)
}

function validate_url(string) {
  try {
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
}

// File Upload (send via form data with workspace slug)
const upload_embed_document = (event) => {
    console.log("upload_event: ", event)
    loader.value = true
    for (let i = 0; i < event.files.length; i++) {
        console.log(event.files[i])
        let formData = new FormData()
        formData.append('file', event.files[i])
        formData.append('workspace', workspace.value)
        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/llm/upload-embed-document', formData, {headers: {'Content-Type': 'multipart/form-data'}})
        .then(response => {
            console.log(response.data)
            toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Datei wurde erfolgreich hochgeladen', life: 3000});
            get_workspace()
            loader.value = false
        })
    }
}

</script>
<style>
.p-card-footer {
    padding: 0!important;
}
</style>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>
<template>
    <Toast />
    <ConfirmDialog />
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <BlockUI :fullScreen="true" :blocked="loader">
        <div class="col-12 mb-2">
            <Toolbar>
                <template #start>
                    <span class="mr-2 font-bold">Umgebung:</span>
                    <Dropdown class="mr-2" v-model="workspace" :options="workspaces" optionLabel="name" optionValue="slug" placeholder="Umgebung auswählen" style="width: 400px" @change="initiate_workspace()" />
                    <Button :disabled="workspace ? false : true" icon="pi pi-cog" @click="edit_workspace" v-tooltip="'Umgebung bearbeiten'" class="mr-2" />
                    <Button icon="pi pi-plus" @click="new_workspace_modal = true" v-tooltip="'Neue Umgebung hinzufügen'" class="mr-4" />
                    <Button :disabled="workspace ? false : true" icon="pi pi-folder" @click="view_documents_slider = true" label="Dokumente" v-tooltip="'Dokumente ansehen und hochladen'" class="mr-2" :badge="String(workspace_documents.length)" />
                </template>
                <template #end>
                    <div class="flex gap-2">
                        <Button rounded icon="pi pi-question" v-tooltip.left="'Wie funktioniert Snappy LLM?'" @click="how_it_works = true" />
                        <Button rounded icon="pi pi-star" v-tooltip.left="'Coming soon...'" @click="roadmap = true" />
                    </div>
                </template>
            </Toolbar>
        </div>
        <div style="padding: 1rem" class="col-12 formgrid grid">
            <div style="background: #f8fafc; border: 1px solid #e2e8f0; padding: 0.8rem; border-radius: 6px; gap: 0.5rem;" class="field col-12 flex">
                <Textarea  :disabled="workspace ? false : true" v-model="text_prompt" rows="1" cols="50" style="width: 800px; height: 50px;" class="mr-2" />
                <Button :disabled="!text_prompt ? true : false" @click="send_chat()" label="Senden" icon="pi pi-caret-right" class="mr-2" />
                <div class="flex align-content-center flex-wrap mr-2">
                    <InputSwitch :disabled="workspace ? false : true" v-model="online_switch" class="mr-2 mt-1" />
                    <Chip v-if="online_switch" label="Online" icon="pi pi-circle-fill" class="bg-green-200" />
                    <Chip v-if="!online_switch" label="Offline" icon="pi pi-circle" />
                </div>
                <Button v-if="online_switch" label="URL herunterladen" icon="pi pi-download" class="mr-2" @click="url_download_modal = true" />
            </div>
        </div>
        <div class="col-12 formgrid grid">
            <div style="background: #f8fafc; border: 1px solid #e2e8f0; padding: 1.5rem; border-radius: 6px; gap: 0.5rem;" class="field col-9">
                <div v-if="snappy_chat">Chat: <span class="font-bold ml-2">{{ snappy_chat.name }}</span><i class="pi pi-pencil ml-2 cursor-pointer" @click="edit_snappy_chat_name_modal = true, snappy_chat_name = snappy_chat.name" /></div>
                <div v-if="snappy_prompts.length == 0" class="mt-6 flex justify-content-center">
                    <div style="font-size: 1.2rem">Lege los, indem du eine Anfrage eingibst oder einen Chat aus deinem Verlauf lädst.</div>
                </div>
                <div v-for="(prompt, key) in snappy_prompts" :key="key">
                    <Card v-if="prompt.type == 'textResponse'" class="w-full overflow-hidden mt-3 shadow-3">
                        <!--
                        <template #header>
                            <div class="flex justify-between bg-blue-500 h-1rem" v-tooltip.top="'Offline'" hidden></div>
                        </template>
                        -->
                        <template #subtitle>{{prompt.prompt}} <i class="pi pi-refresh cursor-pointer ml-1" @click="text_prompt = prompt.prompt" /></template>
                        <template #content>
                            <div style="white-space: pre-wrap;" class="m-0" v-html="prompt.textResponse">
                            </div>
                        </template>
                        <template #footer>
                            <div class="flex">
                                <Button @click="view_sources(prompt.sources)" icon="pi pi-file" label="Quellen" style="padding: 0.3rem 0.6rem 0.3rem 0.3rem;" class="p-button-text" size="xs" v-tooltip.top="'Dateien und Auszüge, aus denen das LLM die Antwort erstellt hat'" :badge="String(prompt.sources.length)" badgeSeverity="danger" />
                                <Button icon="pi pi-copy" @click="copy_to_clipboard(prompt.textResponse)" class="p-button-text ml-auto" rounded v-tooltip.top="'In Zwischenablage kopieren'" />
                                <Button icon="pi pi-save" class="p-button-text" rounded v-tooltip.top="'In die LLM speichern'" />
                                <Button icon="pi pi-trash" class="p-button-text" severity="danger" rounded v-tooltip.top="'Löschen'" @click="remove_snappy_prompt(prompt.id)" />
                            </div>
                        </template>
                    </Card>
                    <Card v-if="prompt.type == 'link'" class="w-full overflow-hidden mt-3 shadow-3">
                        <!--
                        <template #header>
                            <div class="flex justify-between bg-blue-500 h-1rem" v-tooltip.top="'Offline'" hidden></div>
                        </template>
                        -->
                        <template #subtitle>{{prompt.prompt}} <i class="pi pi-refresh cursor-pointer ml-1" @click="text_prompt = prompt.prompt" /></template>
                        <template #content>
                            <div style="white-space: pre-wrap;" class="m-0" v-html="prompt.pageContent">
                            </div>
                        </template>
                        <template #footer>
                            <div class="flex">
                                <Button v-if="!prompt.embed" @click="embed_document(prompt.location)" icon="pi pi-download" label="In die LLM speichern" style="padding: 0.3rem 0.6rem 0.3rem 0.3rem;" class="p-button-text" size="xs" v-tooltip.top="'Speichere diesen Text als Dokument im LLM'" />
                                <Chip v-if="prompt.embed" label="In LLM eingebettet" icon="pi pi-check" class="bg-green-200" />
                                <Button icon="pi pi-copy" @click="copy_to_clipboard(prompt.textResponse)" class="p-button-text ml-auto" rounded v-tooltip.top="'In Zwischenablage kopieren'" />
                                <Button icon="pi pi-trash" class="p-button-text" severity="danger" rounded v-tooltip.top="'Löschen'" @click="remove_snappy_prompt(prompt.id)" />
                            </div>
                        </template>
                    </Card>
                </div>
            </div>
            <div class="field col-3">
                <div style="background: #f8fafc; border: 1px solid #e2e8f0; border-radius: 6px;" class="ml-3 p-2">
                    <div class="p-3">
                        <Button :disabled="workspace ? false : true" icon="pi pi-plus" label="Neuer Chat" @click="new_chat()" />
                    </div>
                    <div class="p-2">
                        <span class="font-bold">Verlauf</span>
                    </div>
                    <div v-for="(snappy_chat, key) in snappy_chats" :key="key" class="flex justify-content-start align-content-center justify-content-center flex-wrap">
                        <div class="col-12 formgrid grid mt-2">
                            <div class="col">
                                <Chip class="cursor-pointer p-3 w-full hover:bg-white hover:shadow-3" @click="select_snappy_chat(snappy_chat.id)" v-if="snappy_chat_id != snappy_chat.id">
                                    <i class="pi pi-history mr-2 ml-2" style="font-size: 1rem" />
                                    {{ snappy_chat.name }}
                                </Chip>
                                <Chip class="cursor-pointer p-3 w-full bg-primary text-white shadow-3" v-if="snappy_chat_id == snappy_chat.id">
                                    <i class="pi pi-history mr-2 ml-2" style="font-size: 1rem" />
                                    {{ snappy_chat.name }}
                                </Chip>
                            </div>
                            <div class="col-fixed" style="width: 120px">
                                <Button icon="pi pi-pencil" class="p-button-text" rounded v-tooltip.top="'Dialog-Set bearbeiten'" @click="edit_snappy_chat_name_modal = true, snappy_chat_name = snappy_chat.name" />
                                <Button icon="pi pi-trash" class="p-button-text" severity="danger" rounded v-tooltip.top="'Dialog-Set löschen'" @click="remove_snappy_chat(snappy_chat.id)" />
                            </div>
                        </div>
                    </div>
                    <div v-if="snappy_chats.length == 0" class="p-3">
                        <span>Verlauf ist leer</span>
                    </div>
                    <div v-if="snappy_chats.length > 0" class="p-3">
                    </div>
                </div>
            </div>
        </div>
    </BlockUI>
    <!------------------------------------------------------------------------------------->
    <!------------------------------------------------------------------------------------->
    <!------------------------------ Dialog: New Workspace -------------------------------->
    <!------------------------------------------------------------------------------------->
    <!------------------------------------------------------------------------------------->
    <Dialog header="Neue Umgebung" v-model:visible="new_workspace_modal" :modal="true" style="width: 800px">
        <div class="col-12 formgrid grid">
            <div class="field col-12">
            <div class="p-2">
                Erstelle eine neue Umgebung die über Berechtungen und Einstellungen verfügt, die von der Standardumgebung abweichen.
            </div>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="name" v-model="new_workspace_name" class="w-full" />
                    <label for="name">Umgebungs-Name</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="name" disabled v-model="new_workspace_owner" class="w-full" />
                    <label for="name">Besitzer</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <MultiSelect id="users_select" class="w-full" v-model="new_workspace_users" :options="users_select" optionLabel="name" optionValue="email" :filter="true" display="chip" />
                    <label for="users_select">Zugang erteilen</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <Button :disabled="!new_workspace_name ? true : false" @click="new_workspace()" label="Umgebung erstellen" class="w-auto mr-2" icon="pi pi-check" />
                <Button @click="new_workspace_modal = false, new_workspace_name = ''" label="Abbrechen" class="w-auto p-button-danger" icon="pi pi-times" />
            </div>
        </div> 
    </Dialog>
    <!------------------------------------------------------------------------------------->
    <!------------------------------------------------------------------------------------->
    <!----------------------------- Dialog: Edit Chat Name -------------------------------->
    <!------------------------------------------------------------------------------------->
    <!------------------------------------------------------------------------------------->
    <Dialog header="Chat-Namen editieren" v-model:visible="edit_snappy_chat_name_modal" :modal="true" style="width: 800px">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="name" v-model="snappy_chat_name" class="w-full" />
                    <label for="name">Chat-Namen</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <Button :disabled="!snappy_chat_name ? true : false" @click="edit_snappy_name()" label="Speichern" class="w-auto mr-2" icon="pi pi-save" />
                <Button @click="edit_snappy_chat_name_modal = false" label="Abbrechen" class="w-auto p-button-danger" icon="pi pi-times" />
            </div>
        </div> 
    </Dialog>
    <!------------------------------------------------------------------------------------->
    <!------------------------------------------------------------------------------------->
    <!------------------------------ Dialog: Download URL --------------------------------->
    <!------------------------------------------------------------------------------------->
    <!------------------------------------------------------------------------------------->
    <Dialog header="URL herunterladen" v-model:visible="url_download_modal" :modal="true" style="width: 800px">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="name" v-model="website_url" class="w-full" />
                    <label for="name">Webseiten-URL</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <Button :disabled="validate_url(website_url) ? false : true" @click="download_url()" label="URL Herunterladen" class="w-auto mr-2" icon="pi pi-save" />
                <Button @click="url_download_modal = false" label="Abbrechen" class="w-auto p-button-danger" icon="pi pi-times" />
            </div>
        </div> 
    </Dialog>
    <!------------------------------------------------------------------------------------->
    <!------------------------------------------------------------------------------------->
    <!----------------------------- Dialog: Edit Workspace -------------------------------->
    <!------------------------------------------------------------------------------------->
    <!------------------------------------------------------------------------------------->
    <Dialog header="Umgebung bearbeiten" v-model:visible="edit_workspace_modal" :modal="true" style="width: 800px">
        <div class="col-12 formgrid grid">
            <div class="field col-12">
            <div class="p-2">
                Wähle den gewünschten LLM und erteile Zugang zur Umgebung.
            </div>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="name" v-model="workspace_edit.name" class="w-full" />
                    <label for="name">Umgebungs-Name</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <Dropdown id="llm" v-model="workspace_edit.agentModel" :options="llms" optionLabel="name" optionValue="slug" placeholder="LLM auswählen" class="w-full" />
                    <label for="llm">LLM</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="name" disabled v-model="workspace_edit.owner" class="w-full" />
                    <label for="name">Besitzer</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <MultiSelect id="users_select" class="w-full" v-model="workspace_edit.users" :options="users_select" optionLabel="name" optionValue="email" :filter="true" display="chip" />
                    <label for="users_select">Zugang erteilen</label>
                </span>
            </div>
            <div v-if="!delete_workspace_check" class="field col-12 mt-3">
                <Button :disabled="!workspace_edit.name ? true : false" @click="update_workspace()" label="Änderungen speichern" class="w-auto mr-2" icon="pi pi-check" />
                <Button @click="edit_workspace_modal = false" label="Abbrechen" class="w-auto p-button-danger" icon="pi pi-times" />
            </div>
            <div class="field col-12 mt-3">
                <div class="flex align-items-center">
                    <Checkbox v-model="delete_workspace_check" inputId="delete" class="mt-2" :binary="true" />
                    <label for="delete" class="ml-2 mt-2">Umgebung löschen </label>
                </div>
            </div>
            <div v-if="delete_workspace_check" class="field col-12 mt-3">
                <Button @click="delete_workspace()" label="Umgebung löschen" class="w-auto p-button-danger" icon="pi pi-trash" />
            </div>
        </div> 
    </Dialog>
    <!------------------------------------------------------------------------------------->
    <!------------------------------------------------------------------------------------->
    <!---------------------------- Dialog: How does it work ------------------------------->
    <!------------------------------------------------------------------------------------->
    <!------------------------------------------------------------------------------------->
    <Dialog header="Wie funktionert es?" v-model:visible="how_it_works" :modal="true" style="width: 80%">
        <div class="col-12 formgrid grid">
            <div class="field col-12">
            <p>Das Wichtigste zuerst: Das ist alles Snappy! Das bedeutet, dass <strong>alles</strong>, was du siehst und mit dem du interagieren kannst, vollständig editierbar ist. Unser System ist auf diese Weise aufgebaut, weil wir wissen, dass ein einziges System nicht für alle passt. Jeder Kunde kann sein LLM so anpassen, wie er es möchte, und kann jede Funktion hinzufügen, die er benötigt und die technisch möglich ist (und das ist praktisch alles!).</p>
            <p>
                <img src="../assets/llm_diagram.png" />
            </p>
            </div>
        </div>
    </Dialog>
    <!------------------------------------------------------------------------------------->
    <!------------------------------------------------------------------------------------->
    <!--------------------------------- Dialog: Roadmap ----------------------------------->
    <!------------------------------------------------------------------------------------->
    <!------------------------------------------------------------------------------------->
    <Dialog header="Updates Roadmap" v-model:visible="roadmap" :modal="true" style="width: 80%">
        <div class="col-12 formgrid grid">
            <div class="field col-12">
                <p>Das Snappy DEVOPS-Team arbeitet aktiv daran, alte Wege der Daten- und Dokumentenverwaltung mit LLM und serverloser Technologie zu modernisieren. Wenn 1 % von 99 % der Unternehmensdaten genutzt wird, ermöglicht der LLM-Ansatz von Snappy den Kunden, schneller zu den 1 % zu gelangen und dabei den Kontext / die Intelligenz der anderen 99 % zu nutzen. Mit modernen Ansätzen wird das Einrichten eines brandneuen und modernen LLM-gestützten DMS mit Snappy einfach und schnell sein. Migrationen inklusive.</p>
            </div>
            <div class="field col-12">
                <DataTable :value="updates_roadmap" :rows="10" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[10,20,50]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                    <Column field="name" header="Name" style="width: 300px">
                        <template #body="slotProps">
                            <span class="font-bold">{{slotProps.data.name}}</span>
                        </template>
                    </Column>
                    <Column field="estimate" header="Schätzung" sortable style="width: 200px">
                        <template #body="slotProps">
                            <span>{{slotProps.data.estimate}}</span>
                        </template>
                    </Column>
                    <Column field="details" header="Details">
                        <template #body="slotProps">
                            <span>{{slotProps.data.details}}</span>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </Dialog>
    <!------------------------------------------------------------------------------------->
    <!------------------------------------------------------------------------------------->
    <!-------------------------------- Sidebar: Sources ----------------------------------->
    <!------------------------------------------------------------------------------------->
    <!------------------------------------------------------------------------------------->
    <Sidebar header="Quellen" v-model:visible="view_sources_slider" position="left" style="width: 50%">
        <Accordion expandIcon="pi pi-plus" collapseIcon="pi pi-minus" class="mt-3">
            <AccordionTab v-for="(source, key) in sources" :key="key" >
                <template #header>
                    <span class="flex align-items-center gap-2 w-full">
                        <Avatar icon="pi pi-file" shape="circle" />
                        <span class="font-bold white-space-nowrap">{{ source.title }}</span>
                        <Badge :value="String(Math.floor(source.score * 100)) + '% Genauigkeit'" class="ml-auto mr-2" />
                    </span>
                </template>
                <p class="m-0" style="white-space: pre-wrap;" v-html="source.text"></p>
            </AccordionTab>
        </Accordion>
    </Sidebar>
    <!------------------------------------------------------------------------------------->
    <!------------------------------------------------------------------------------------->
    <!------------------------------- Sidebar: Documents ---------------------------------->
    <!------------------------------------------------------------------------------------->
    <!------------------------------------------------------------------------------------->
    <Sidebar header="Dokumente" v-model:visible="view_documents_slider" position="left" style="width: 70%">
        <div class="col-12 formgrid grid">
            <div class="field col-12">
                <div class="p-2">
                    Hier kannst du Dokumente hochladen, die von deinem LLM verarbeitet werden sollen. Unterstützt werden .doc, .docx, .pdf, .mbox, .txt und .md Dateien. Die maximale Dateigrösse beträgt 10MB.
                </div>
            </div>
            <div class="field col-12 mt-1">
                <FileUpload name="demo[]" :previewWidth="0" @uploader="upload_embed_document($event)" :customUpload="true" :multiple="true" accept=".doc,.docx,.pdf,.mbox,.txt,.md" :maxFileSize="10000000" chooseLabel="Auswählen" uploadLabel="Hochladen" cancelLabel="Abbrechen">
                    <template #empty>
                        <div class="flex align-items-center justify-content-center flex-column">
                            <i class="pi pi-upload border-2 border-circle p-5 text-4xl text-400 border-400" />
                            <p class="mt-4 mb-0">Dateien zum Hochladen mit Drag & Drop hierher ziehen.</p>
                        </div>
                    </template>
                </FileUpload>
            </div>
            <small class="p-2 text-red-700">Dokumente können zur Zeit nicht gelöscht werden. Wir werden die Testumgebung regelmässig leeren.</small>
            <div class="field col-12 mt-3">
                <Toolbar>
                    <template #start>
                        <span class="p-input-icon-left mr-2">
                            <i class="pi pi-search" />
                            <InputText v-model="filter_documents['global'].value" placeholder="Dateinamen Suche" style="width: 300px" />
                        </span>
                    </template>
                </Toolbar>
            </div>
            <div class="field col-12">
                <DataTable  v-model:filters="filter_documents" :value="workspace_documents" :rows="20" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20,50,100]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                    <Column field="name" header="Dokument" sortable>
                        <template #body="slotProps">
                            <span class="font-bold">{{slotProps.data.title}}</span>
                        </template>
                    </Column>
                    <Column field="createdAt" header="Hochgeladen" sortable style="width: 200px">
                        <template #body="slotProps">
                            <span>{{format_swiss_date_time(slotProps.data.createdAt)}}</span>
                        </template>
                    </Column>
                    <Column field="wordCount" header="Wörter/Tokens" sortable style="width: 200px">
                        <template #body="slotProps">
                            <span>{{slotProps.data.wordCount}} / {{slotProps.data.token_count_estimate}}</span>
                        </template>
                    </Column>
                    <Column style="width: 150px">
                        <template #body="slotProps">
                            <Button icon="pi pi-trash" rounded severity="danger" :disabled="true" @click="remove_document(slotProps.data.name)" />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </Sidebar>
    
</template>