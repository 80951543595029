<script setup>
import { ref, onMounted, watch, defineProps, defineEmits } from 'vue'
import axios from 'axios'
import { CheckIcon } from "@heroicons/vue/20/solid"
import LoadingSpinner from '@/core/components/UI/Loading-Spinner.vue'
// define props
const props = defineProps({
    questions: { type: Object, required: true }
})
const emits = defineEmits(['chatCallback', 'chatloading'])

const gpt_content = ref("")
const gpt_answer = ref("")
const loading = ref(false)
const disabled = ref(false)
const clipboard = ref(false);

const run = async (question) => {
    loading.value = true;
    await gpt_question(question).then(() => {
        loading.value = false;
    })
}

const gpt_question = async (question) => {
    try {
        const payload = { "question": question };
        const response = await axios.post(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/dashboard/chatgpt-question`, payload);
        if (response && response.data) {
            gpt_answer.value = response.data.message;
            gpt_content.value = gpt_answer.value;
        }
    } catch (error) {
        console.error('Error querying GPT:', error);
    }
};


watch(loading, res => {
    disabled.value = res;
    clipboard.value = false;
    emits('chatloading', res)
})

const save_answers = () => {
    emits('chatCallback',
        {
            "chatGptAnswers": gpt_content.value,
        }
    )
}

const answers2Clipboard = () => {
    navigator.clipboard.writeText(gpt_content.value).then(function () {
        clipboard.value = true;
    }, function (err) {
        console.error('Could not copy text: ', err);
    });
}
</script>

<template>
    <div class="">
        <div class="my-5">

            <div class="flex flex-row">
                <div v-for="(q, index) in props.questions" :key="index" class="pr-2">
                    <Button :label="q.button_name" @click="run(q.question)" :disabled="disabled" :loading="loading" class="w-auto mx-2 p-button" icon="pi pi-bolt" />
                </div>
            </div>

        </div>
        <div class="" v-if="!loading">
            <textarea v-model="gpt_content" class="w-full border border-gray-300 rounded-md h-56"></textarea>
        </div>

        <div v-else>
            <div class="flex flex-col items-center justify-center border border-gray-200 p-5">
                <div>Die KI arbeitet. Dies kann mehrere Minuten dauern.</div>
                <LoadingSpinner size="12" color="fill-primary-600" background-dark="dark:text-gray-100" />
            </div>
        </div>

        <div class="my-5 flex justify-start items-center transition ease-in-out duration-300" v-if="gpt_content.length > 0">

            <div class="m-2"><Button label="Texte übernehmen" @click="save_answers()" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" /></div>
            <div class="m-2"><Button label="Text in die Zwischenablage" @click="answers2Clipboard" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" v-if="clipboard == false" />
                <div v-else class="text-gray-400 flex m-2">
                    <CheckIcon class="h-6 w-6 text-gray-400" aria-hidden="true" /> Daten kopiert
                </div>
            </div>
        </div>
    </div>
</template>