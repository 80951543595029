<template>
    <Menu as="div" class="relative">
        <div>
            <MenuButton class="bg-[#F5F7FF] p-2  mx-4 my-2 flex max-w-xs items-center rounded-2xl text-sm focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2">
                <main-avatar :status="adminstatus" />

                <div class=" text-start mx-2 text-xs">
                    <div class=" font-normal">{{ auth.userdata.user_firstname }} {{ auth.userdata.user_lastname }}</div>
                    <div class=" font-medium truncate">{{ appmode.fullname }}</div>
                </div>

                <ChevronDownIcon class="h-4 w-4" />
                <span class="sr-only">User Menü öffnen</span>
            </MenuButton>
        </div>

        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95"  @click="sidebar = false" >
            <MenuItems class="absolute right-0 z-10 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <MenuItem v-for="item in userNavigation" :key="item.name" v-slot="{ active }">
                <router-link :to="item.href" active-class="bg-gray-200" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']" >{{ item.name }}</router-link>
                </MenuItem>
            </MenuItems>
        </transition>
    </Menu>
</template>

<script setup>
import { ref, defineEmits, watch } from 'vue';
import MainAvatar from './UI/Main-Avatar.vue';
import { useCustomerStore } from '@/core/store/CustomerStore';
import { useAuthStore } from '@/core/store/AuthStore';
import {
    Dialog,
    DialogPanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot
} from '@headlessui/vue'
import {
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    XMarkIcon,
    Bars3BottomLeftIcon,
    CodeBracketIcon,
    ChatBubbleLeftIcon, InformationCircleIcon
} from '@heroicons/vue/24/outline'

const auth = useAuthStore();
const adminstatus = ref(auth.getAuth(0));
const customerStore = useCustomerStore();
const appmode = customerStore.getCustomer;
const emits = defineEmits(['sidebarOpen'])
const sidebar = ref(true);

watch(sidebar, res => {
    emits('sidebarOpen', res);
})

const userNavigation = [
    { name: 'Profil', href: '/dashboard/profile' },
    //{ name: 'Einstellungen', href: '/dashboard/settings' },
    //{ name: 'Logout', href: '/logout' },
]
</script>