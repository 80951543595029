<template>
<router-view />
</template>

<script setup>
import './core/assets/tailwind.css';
import {onMounted } from 'vue';
import { parseJwt } from '@/core//var/jwt';
import { useAuthStore } from '@/core/store/AuthStore';

const auth = useAuthStore();

onMounted(async () => {
    const jwtoken = parseJwt(localStorage.getItem(process.env.VUE_APP_NEURAXIS_TOKENNAME));

    if(jwtoken !== false){
        userdataToStore(jwtoken);
    }
});

const userdataToStore = userdata =>{
    auth.setUserData(userdata);
    auth.setAuth(userdata);
}

</script>