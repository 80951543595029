<script setup>
import { ref, onMounted } from 'vue'
import axios from 'axios'
import { useCustomerStore } from '@/core/store/CustomerStore';
import LoadingSpinner from '@/core/components/UI/Loading-Spinner.vue';

const getQueryParam = key => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return urlSearchParams.get(key);
}

const token = ref(false)
const user_email = ref(false)
const user_firstname = ref("")
const user_lastname = ref("")
const user_password = ref("")
const user_password_repeat = ref("")
const user_salutation = ref()
const user_mobile_prefix = ref()
const user_mobile = ref()
const password_too_weak = ref(false)

const customerStore = useCustomerStore();
const appmode = customerStore.getCustomer;
const loading = ref(true)
const button_loading = ref(false);
const remote_salutations = ref([]);



const mobile_codes = ref([
    { name: '+41' },
    { name: '+49' },
    { name: '+33' },
    { name: '+39' },
    { name: '+43' },
    { name: '+44' },
    { name: '+45' },
]);


const countries = [
    { name: 'Schweiz', code: 'CH' },
    { name: 'Deutschland', code: 'DE' },
    { name: 'Österreich', code: 'AT' },
    { name: 'Frankreich', code: 'FR' },
    { name: 'Italien', code: 'IT' },
    { name: 'Spanien', code: 'ES' },
    { name: 'Niederlande', code: 'NL' },
    { name: 'Belgien', code: 'BE' },
    { name: 'Luxemburg', code: 'LU' },
    { name: 'Liechtenstein', code: 'LI' },
    { name: 'Vereinigtes Königreich', code: 'GB' },
    { name: 'Irland', code: 'IE' },
    { name: 'Dänemark', code: 'DK' },
    { name: 'Norwegen', code: 'NO' },
    { name: 'Schweden', code: 'SE' },
    { name: 'Finnland', code: 'FI' },
    { name: 'Polen', code: 'PL' },
    { name: 'Tschechien', code: 'CZ' },
    { name: 'Slowakei', code: 'SK' },
    { name: 'Ungarn', code: 'HU' },
    { name: 'Slowenien', code: 'SI' },
    { name: 'Kroatien', code: 'HR' },
    { name: 'Bosnien und Herzegowina', code: 'BA' },
    { name: 'Serbien', code: 'RS' },
    { name: 'Montenegro', code: 'ME' },
    { name: 'Albanien', code: 'AL' },
    { name: 'Mazedonien', code: 'MK' },
    { name: 'Griechenland', code: 'GR' },
    { name: 'Türkei', code: 'TR' },
    { name: 'Zypern', code: 'CY' },
    { name: 'Malta', code: 'MT' },
    { name: 'Island', code: 'IS' },
    { name: 'Grönland', code: 'GL' },
    { name: 'Färöer', code: 'FO' },
    { name: 'Estland', code: 'EE' }
];

onMounted(async () => {
    const tkn = getQueryParam('token');
    token.value = tkn;

    try {
        const [response, dataSalutations] = await Promise.all([
            token.value ? axios.post(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/dashboard/invite-token`, { token: token.value, channel: appmode.customer }) : Promise.resolve(null),
            axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/dashboard/bexio-salutations`)
        ]);

        if (response && response.data.message == 'success') {
            user_email.value = response.data.user_email;
            loading.value = false;
        }

        if (response && response.data.message == 'missmatch') {
            loading.value = false;
        }

        remote_salutations.value = dataSalutations.data;

    } catch (error) {
        console.error('Error loading data:', error);
    } 
})


const register = () => {
    button_loading.value = true
    if (user_password.value != user_password_repeat.value || user_password.value.length <= 8 || user_password.value.match(/[a-z]/g) == null || user_password.value.match(/[A-Z]/g) == null || user_password.value.match(/[0-9]/g) == null || user_password.value.match(/[^a-zA-Z\d]/g) == null) {
        password_too_weak.value = true;
        button_loading.value = false
        return;
    }
    const workload = {
        token: token.value,
        user_email: user_email.value,
        user_salutation: user_salutation.value,
        user_firstname: user_firstname.value,
        user_lastname: user_lastname.value,
        user_mobile_prefix: user_mobile_prefix.value,
        user_mobile: `${user_mobile.value}`,
        user_password: user_password.value,
        channel: appmode.customer
    }

    axios.post(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/dashboard/register`, workload)
        .then(response => {
            if (response.data.message == 'success') {
                setTimeout(() => {
                    button_loading.value = true
                    window.location.href = '/'
                }, 3000)
            }
        })
}
const getLogo = () => {
    return require(`@/customers/${appmode.customer}/assets/${appmode.assets.logo}`);
}

</script>


<template>
    <div class="flex justify-center items-center h-full" v-if="loading">
        <LoadingSpinner size="16" />
    </div>
    <div class="flex justify-center mt-6" v-else>
        <div class="bg-white p-4 shadow-md rounded w-full lg:w-3/4">
            <div class="text-center mb-5">
                <img class="mx-auto h-12 w-auto " :src="getLogo()" alt="" />
                <div class="text-black text-3xl font-medium mb-3">Snappy Dashboard</div>

                <span v-if="user_email" class="text-gray-600 font-medium leading-relaxed">Sie wurden in <span class="text-primary-500">{{ appmode.fullname }}</span> Snappy-Instanz eingeladen!</span>
                <span v-if="!user_email" class="text-red-500 font-medium leading-relaxed">Dieser Einladungslink ist abgelaufen oder nicht mehr gültig!</span>
            </div>

            <div v-if="user_email">

                <label for="salutation" class="block text-black font-medium mt-2">Anrede</label>
                <Dropdown v-model="user_salutation" :options="remote_salutations" optionLabel="name" id="user_salutation" class="w-full" required />

                <label for="email" class="block text-black font-medium mt-2">Email</label>
                <InputText disabled id="email" v-model="user_email" type="text" class="w-full mt-3 border p-2 rounded" />

                <label for="firstname" class="block text-black font-medium mt-2">Vornamen</label>
                <InputText id="firstname" v-model="user_firstname" type="text" class="w-full mt-3 border p-2 rounded" />

                <label for="lastname" class="block text-black font-medium mt-2">Nachnamen</label>
                <InputText id="lastname" v-model="user_lastname" type="text" class="w-full mt-3 border p-2 rounded" />

                <label for="password" class="block text-black font-medium mt-2">Passwort</label>
                <InputText id="password" v-model="user_password" type="password" class="w-full mt-3 border p-2 rounded" />
                <small v-if="password_too_weak" class="text-red-500">Passwort zu schwach</small>

                <label for="password_repeat" class="block text-black font-medium mt-2">Passwort wiederholen</label>
                <InputText id="password_repeat" v-model="user_password_repeat" type="password" class="w-full mt-3 border p-2 rounded" />


                <label for="mobile" class="block text-black font-medium mt-2">Mobile</label>
                <div class="flex flex-row">
                    <div class="pr-2">
                        <Dropdown v-model="user_mobile_prefix" :options="mobile_codes" optionLabel="name" option-value="name" />
                    </div>
                    <div class="">
                        <InputText v-model="user_mobile" id="mobile" class="w-auto" />
                    </div>
                </div>


                <Button :disabled="user_email && user_firstname && user_lastname && user_password && user_password_repeat && user_password == user_password_repeat ? false : true" @click="register" label="Registrieren" icon="pi pi-check" class="w-full mt-4 bg-primary-500 hover:bg-primary-700 text-white font-bold py-2 px-4 rounded" :loading="button_loading" />
            </div>
        </div>
    </div>
</template>
