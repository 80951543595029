<template>
    <div class="py-4">
        <!-- Content Components injects -->
        <div class="py-5 rounded-lg border-1  border-gray-200">
            <div class="pb-5">Hallo {{ userdata.user_firstname }} {{ userdata.user_lastname }}</div>
            <div>
                <h3 class="text-lg font-medium leading-6 text-gray-900">Letzte 30 Tage</h3>

                <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                    <div v-for="item in stats" :key="item.id" class="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-12 shadow sm:px-6 sm:pt-6">
                        <dt>
                            <div class="absolute rounded-md bg-primary-500 p-3">
                                <component :is="item.icon" class="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            <p class="ml-16 truncate text-sm font-medium text-gray-500">{{ item.name }}</p>
                        </dt>
                        <dd class="ml-16 flex items-baseline pb-6 sm:pb-7">
                            <p class="text-2xl font-semibold text-gray-900">{{ item.stat }}</p>
                            <p :class="[item.changeType === 'increase' ? 'text-green-600' : 'text-red-600', 'ml-2 flex items-baseline text-sm font-semibold']">
                                <ArrowUpIcon v-if="item.changeType === 'increase'" class="h-5 w-5 flex-shrink-0 self-center text-green-500" aria-hidden="true" />
                                <ArrowDownIcon v-else class="h-5 w-5 flex-shrink-0 self-center text-red-500" aria-hidden="true" />
                                <span class="sr-only"> {{ item.changeType === 'increase' ? 'Increased' : 'Decreased' }} by </span>
                                {{ item.change }}
                            </p>
                            <div class="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                                <div class="text-sm">
                                    <a href="#" class="font-medium text-primary-600 hover:text-primary-500">
                                        Erweitert<span class="sr-only"> {{ item.name }} stats</span></a>
                                </div>
                            </div>
                        </dd>
                    </div>
                </dl>
            </div>

        </div>

        <div class="py-5">
            <user-info :userdata="userdata" />
        </div>
        <!-- Content Components injects end -->
    </div>

</template>

<script setup>
import { onBeforeMount, ref, onMounted} from 'vue';
import {
    UsersIcon,
} from '@heroicons/vue/24/outline'
import { ArrowDownIcon, ArrowUpIcon, PlusIcon } from '@heroicons/vue/20/solid'
import { CursorArrowRaysIcon, EnvelopeOpenIcon } from '@heroicons/vue/24/outline'
import getAuth from '../../var/auth';
import UserInfo from '../Main-UserInfo.vue'
import { GetUserData } from '@/core/var/user';
import Simplestore from '@/core/var/Simplestore';
import { useAuthStore } from '@/core/store/AuthStore';
import { LoadRoles } from '@/core/var/user'

const auth = useAuthStore();
const userdata = ref();


onBeforeMount(async () => {
    getAuth();
    userdata.value = auth.getUserData;
    if(auth.getUserData != null){
        Simplestore.spinner = true;
        await GetUserData({
            user_id: userdata.value.user_id,
            user_email: userdata.value.user_email
        }).then(res => {
            userdata.value = res.data;
            Simplestore.spinner = false;
        });
    }
});


const stats = [
    { id: 1, name: 'Workflows', stat: '71,897', icon: UsersIcon, change: '122', changeType: 'increase' },
    { id: 2, name: 'Durchschnitt', stat: '58.16%', icon: EnvelopeOpenIcon, change: '5.4%', changeType: 'increase' },
    { id: 3, name: 'Click Rate', stat: '24.57%', icon: CursorArrowRaysIcon, change: '3.2%', changeType: 'decrease' },
]

</script>