<template>
    <Toast />
    <div>
        <h3 class="text-lg font-medium leading-6 text-gray-900">Persönliche Informationen</h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">Bitte nutzen Sie eine Adresse, mit der Sie täglich Mails abrufen</p>
    </div>
    <UserData @submit="handleSubmit" />
    <!-- <form class="space-y-8 divide-y divide-gray-200" @submit.prevent="AddUser">
        <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div class="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
                <div class="space-y-6 sm:space-y-5">
                    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="firstname" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Vorname</label>
                        <div class="mt-1 sm:col-span-2 sm:mt-0">
                            <InputText id="firstname" v-model="state.formData.user_firstname" :disabled="disabled" class="w-full max-w-lg" />
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="last-name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Nachname</label>
                        <div class="mt-1 sm:col-span-2 sm:mt-0">
                            <InputText id="last-name" v-model="state.formData.user_lastname" :disabled="disabled" class="w-full max-w-lg" />
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="email" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">E-Mail</label>
                        <div class="mt-1 sm:col-span-2 sm:mt-0">
                            <InputText id="user_email" v-model="state.formData.user_email" :disabled="disabled" class="w-full max-w-lg" />
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="user_street" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Strasse</label>
                        <div class="mt-1 sm:col-span-2 sm:mt-0">
                            <InputText id="user_street" v-model="state.formData.user_street" :disabled="disabled" class="w-full max-w-lg" />
                        </div>
                    </div>
                    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="user_plz" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">PLZ</label>
                        <div class="mt-1 sm:col-span-2 sm:mt-0">
                            <InputText id="user_plz" v-model="state.formData.user_plz" :disabled="disabled" class="w-full max-w-lg" />
                        </div>
                    </div>
                    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="user_city" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Stadt</label>
                        <div class="mt-1 sm:col-span-2 sm:mt-0">
                            <InputText id="user_city" v-model="state.formData.user_city" :disabled="disabled" class="w-full max-w-lg" />
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="user_land" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Land</label>
                        <div class="mt-1 sm:col-span-2 sm:mt-0">
                            <Dropdown id="user_land" v-model="state.formData.user_land" :disabled="disabled" :options="countries" optionLabel="name" class="w-full max-w-lg" />
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="user_land" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Role</label>
                        <div class="mt-1 sm:col-span-2 sm:mt-0">
                            <Dropdown id="user_roles" v-model="user_roles" :disabled="disabled" :options="avail_user_roles" optionLabel="name" optionValue="level" class="w-full max-w-lg" />
                        </div>
                    </div>
                    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="user_mobile" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Telefon</label>
                        <div class="mt-1 sm:col-span-2 sm:mt-0">
                            <InputText id="user_mobile" v-model="state.formData.user_mobile" :disabled="disabled" class="w-full max-w-lg" />
                        </div>
                    </div>
                    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="user_password" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Passwort</label>
                        <div class="mt-1 sm:col-span-2 sm:mt-0">
                            <Password id="user_password" v-model="state.formData.user_password" :disabled="disabled" class="" toggleMask />
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="user_password_repeat" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Passwort wiederholen</label>
                        <div class="mt-1 sm:col-span-2 sm:mt-0 w-full">
                            <Password id="user_password_repeat" v-model="state.formData.user_password_repeat" :disabled="disabled" class="" toggleMask />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="pt-5">
            <div class="flex justify-end">
                <button type="submit" class="ml-3 inline-flex justify-center rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 disabled:bg-gray-100" :disabled="disabled">
                    <LoadingSpinner size="4" color="fill-primary-600" v-if="state.spinner" background-dark="dark:text-gray-100"></LoadingSpinner> Speichern
                </button>

            </div>
        </div>
        </form> -->
    <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-start px-4 py-6 sm:items-start sm:p-6 z-10">
        <MainToast :mode="state.message.messagemode" v-if="state.message.show">{{ state.message.message }}</MainToast>
    </div>

    <main-modal :open="state.openmodal" title="Benutzer angelegt" button="Zurück" @open="getModalStatus">
        <h3>User Passwort</h3>
        {{ state.modaldata.user_pass }}
    </main-modal>


    <Dialog v-model:visible="editDialog" header="Benutzer bearbeiten" :modal="true" class="w-auto md:w-3/4">

        <!-- Buttons -->
        <div class="flex items-center justify-between mt-4">
            <Button :disabled="!formValid" label="Speichern" @click="save_dataset(data_index, true), editDialog = false" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />

            <Button label="Abbrechen" @click="editDialog = false" class="w-auto mr-3 mb-1 p-button-warning" icon="pi pi-times" />

        </div>
    </Dialog>
</template>
<script setup>
import { ref, reactive, onBeforeMount } from 'vue';
import LoadingSpinner from '@/core/components/UI/Loading-Spinner.vue';
import MainToast from '@/core/components/UI/Main-Toast.vue';
import MainModal from '@/core/components/UI/Main-Modal.vue';
import { Message, ErrorLog } from '@/core/var/Message'
import { LoadRoles } from '@/core/var/user';
import getAuth from '@/core/var/auth';
import Simplestore from '@/core/var/Simplestore';
import axios from 'axios';
import { useAuthStore } from '@/core/store/AuthStore';
import UserData from '../../Forms/User-Data.vue'
import { useToast } from 'primevue/usetoast'
import Password from 'primevue/password';

const auth = useAuthStore()
const toast = useToast()

const userdata = auth.getUserData;

const disabled = ref(true);
const user_channel = ref(process.env.VUE_APP_NEURAXIS_CHANNEL_ID);

const user_roles = ref(false);
const avail_user_roles = ref(false);
const state = reactive({
    formData: { user_land: 'Schweiz', user_roles: 'false' },
    spinner: false,
    servererror: false,
    userpass: '',
    openmodal: false,
    modaldata: false,
    message: {
        show: false,
        message: 'Ein schwerer Fehler ist aufgetreten, bitte versuchen Sie es erneut',
        messagemode: 'error'
    },
});

// const countries = [
//     { name: 'Schweiz', code: 'CH' },
//     { name: 'Deutschland', code: 'DE' },
//     { name: 'Österreich', code: 'AT' }
// ]



onBeforeMount(async () => {
    Simplestore.spinner = true
    await getAuth()
    const res = await LoadRoles(auth.getUserRole)
    avail_user_roles.value = res
    Simplestore.spinner = false
    disabled.value = false
});


const getModalStatus = val => {
    state.openmodal = val;
}

const openModal = () => {
    state.openmodal = true;
}

const AddUser = async () => {

    if (user_roles.value == null || user_roles.value == false) {
        Message('Eine Rolle muss angegeben werden', 'error', state);
        return false;
    }

    const payload =
    {
        "user_email": state.formData.user_email,
        "user_firstname": state.formData.user_firstname,
        "user_lastname": state.formData.user_lastname,
        "user_mobile": state.formData.user_mobile,
        "user_active": true,
        "user_channel": user_channel.value,
        "user_roles": [parseInt(user_roles.value)],
        "user_street": state.formData.user_street,
        "user_land": state.formData.user_land,
        "user_city": state.formData.user_city,
        "user_plz": state.formData.user_plz,
        "user_pw": state.formData.user_password
    }
    let pw_error = false;
    if (state.formData.user_password !== state.formData.user_password_repeat) {
        pw_error = true;
        Message('Diese Passwörter passen nicht zueinander', 'error', state);
        state.spinner = false;
        disabled.value = false;
        Simplestore.spinner = false;
        return false;
    }


    if (payload.user_email && pw_error == false) {

        state.spinner = true;
        disabled.value = true;
        Simplestore.spinner = true;
        await axios
            .post("/dashboard/create-user", payload)
            .then((response) => {
                //console.log(response.data);
                if (response.data === false) {
                    Message('Bei der Datenverarbeitung ist ein Fehler aufgetreten', 'error', state);
                    state.spinner = false;
                    Simplestore.spinner = false;
                    disabled.value = false;
                } else {

                    if (response.data.message && response.data.message === 'error') {
                        Message('Bei der Datenverarbeitung ist ein Fehler aufgetreten', 'error', state);
                        state.spinner = false;
                        disabled.value = false;
                        Simplestore.spinner = false;
                    } else if (response.data.message && response.data.message === 'exists') {
                        Message('Diese Mailadresse darf nicht verwendet werden, da sie bereits existiert', 'error', state);
                        state.spinner = false;
                        disabled.value = false;
                        Simplestore.spinner = false;
                    }
                    else if (response.data.message && response.data.message === 'noauth') {
                        Message('Problem bei der Authentifizerung. Bitte einem Dev Bescheid geben', 'error', state);
                        state.spinner = false;
                        disabled.value = false;
                        Simplestore.spinner = false;
                    }
                    else {
                        state.userpass = response.data.pass;
                        state.spinner = false;
                        disabled.value = false;
                        state.message.messagemode = 'success';
                        state.message.message = `Ihre Daten wurden erfolgreich gespeichert.`;
                        state.message.show = true;
                        Simplestore.spinner = false;
                        state.formData = { user_land: 'Schweiz', user_roles: 'false' }
                        window.setTimeout(() => {
                            state.message.show = false;
                        }, 8000)
                    }
                }

            })
            .catch(function (error) {
                state.message.show = true;
                ErrorLog(error);
                window.setTimeout(() => {
                    state.spinner = false
                    state.message.show = false;
                    disabled.value = false;
                    Simplestore.spinner = false;
                }, 3000)


            })
    }
}

const AddUser2 = async (state, user_channel) => {
    if (!state.user_roles) {
        toast.add({ severity: 'error', summary: 'Fehler', detail: 'Eine Rolle muss angegeben werden', life: 3000 });
        return false;
    }

    const payload = {
        "user_salutation": state.user_salutation,
        "user_email": state.user_email,
        "user_firstname": state.user_firstname,
        "user_lastname": state.user_lastname,
        "user_mobile_prefix": state.user_mobile_prefix,
        "user_mobile": state.user_mobile,
        "user_active": true,
        "user_channel": user_channel.value,
        "user_roles": [parseInt(state.user_roles)],
        "user_street": state.user_street,
        "user_land": state.user_land,
        "user_city": state.user_city,
        "user_plz": state.user_plz,
        "user_pw": state.user_password
    };

    if (state.user_password !== state.user_password_repeat) {
        toast.add({ severity: 'error', summary: 'Fehler', detail: 'Diese Passwörter passen nicht zueinander', life: 3000 });
        state.spinner = false;
        Simplestore.spinner = false;
        return false;
    }

    if (!payload.user_email) {
        return true;
    }

    state.spinner = true;
    Simplestore.spinner = true;

    const response = await axios.post("/dashboard/create-user", payload);
    const { data } = response;

    if (!data) {
        toast.add({ severity: 'error', summary: 'Fehler', detail: 'Bei der Datenverarbeitung ist ein Fehler aufgetreten', life: 3000 });
        return false;
    } else if (data.message === 'error') {
        toast.add({ severity: 'error', summary: 'Fehler', detail: 'Bei der Datenverarbeitung ist ein Fehler aufgetreten', life: 3000 });
        return false;
    } else if (data.message === 'exists') {
        toast.add({ severity: 'error', summary: 'Fehler', detail: 'Diese Mailadresse darf nicht verwendet werden, da sie bereits existiert', life: 3000 });
        return false;
    } else if (data.message === 'noauth') {
        toast.add({ severity: 'error', summary: 'Fehler', detail: 'Problem bei der Authentifizerung. Bitte einem Dev Bescheid geben', life: 3000 });
        return false;
    } else {
        state.userpass = data.pass;
        state.spinner = false;
        Simplestore.spinner = false;
        return true;
    }

};

const handleSubmit = async (formData) => {
    console.log(formData)
    const res = await AddUser2(formData, user_channel.value)
    console.log(res)
    if (res) {
        toast.add({ severity: 'success', summary: 'Erfolg', detail: 'Benutzer wurde erfolgreich angelegt', life: 6000 })
    }
}
</script>