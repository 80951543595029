<template>
    <div class="w-full md:w-auto rounded-xl bg-dark-04 text-white px-4 py-7 text-center m-4 shadow-md relative" v-if="closed">
        <XMarkIcon class="h-6 w-6 absolute top-2 right-2 text-gray-200 z-10 hover:text-white cursor-pointer" @click="closeButton" />
        <h4 class="mb-2 text-base font-bold">
            Get Infos <br>Into Your Inbox!
        </h4>
        <p class="text-dark-01 text-xs mb-6">Soon</p>

        <a href="#" @click="toggle" class="bg-primary-500 rounded-lg transition-all text-white hover:text-gray-100 hover:bg-primary-400 px-7 py-2 flex items-center gap-2 w-fit mx-auto hover:bg-primary text-xs font-semibold">
            Subscribe
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" fill="none">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.2" d="M10.12 3.953 14.167 8l-4.047 4.047M2.833 8h11.22"></path>
            </svg>
        </a>
    </div>

    <OverlayPanel ref="op" appendTo="body" :showCloseIcon="true" id="overlay_panel" class=" w-40">
        <div>
            <div class="my-1"><img class="h-8 w-auto " src="../../assets/v2/Snappy_v2_logo.svg" alt="Snappy" /></div>
            <div class="my-1">Coming soon!</div>
        </div>
    </OverlayPanel>
</template>
<script setup>
import { ref } from 'vue';
import { XMarkIcon } from '@heroicons/vue/20/solid'
const item = 'closed_infobox';

const closed = ref(localStorage.getItem(item) === 'false' ? false : true);

const closeButton = () => {
    closed.value = false;
    localStorage.setItem(item, closed.value);
}
const op = ref();
const toggle = (event) => {
    op.value.toggle(event);
}
</script>