<template>

    <div class="overflow-hidden bg-white shadow sm:rounded-lg w-full">

        <div class="px-4 py-5 sm:px-6">
            <h3 class="text-lg font-medium leading-6 text-gray-900">Stammdaten</h3>
            <p class="mt-1 max-w-2xl text-sm text-gray-500">Ihre Account-Informationen <router-link to="/dashboard/profile" class="text-primary-600">ändern</router-link></p>
        </div>

        <div class="ml-5 mt-4 mb-2">
            <div class="flex items-center">
                <div class="flex-shrink-0">
                    <img class="h-12 w-12 rounded-full" :src="props.userdata.user_avatar" alt="" v-if="props.userdata.user_avatar" />
                    <span class="inline-block h-12 w-12 overflow-hidden rounded-full bg-gray-100" v-else>
                        <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                    </span>

                </div>
                <div class="ml-4">
                    <h3 class="text-base font-semibold leading-6 text-gray-900">{{ props.userdata.user_firstname }} {{ props.userdata.user_lastname }}</h3>
                    <p class="text-sm text-gray-500">
                        <a href="#">{{ props.userdata.user_email }}</a>
                    </p>
                </div>
            </div>
        </div>

        <div class="border-t border-gray-200">
            <dl>
                <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">Vorname</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                        <span v-if="props.userdata.user_firstname">{{ props.userdata.user_firstname }}</span>
                        <div v-else class="animate-pulse h-3 w-21 bg-gray-200 rounded col-span-2"></div>
                    </dd>
                </div>
                <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">Nachname</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                        <span v-if="props.userdata.user_lastname">{{ props.userdata.user_lastname }}</span>
                        <div v-else class="animate-pulse h-3 w-21 bg-gray-200 rounded col-span-2"></div>
                    </dd>
                </div>
                <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">E-Mail</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                        <span v-if="props.userdata.user_email">{{ props.userdata.user_email }}</span>
                        <div v-else class="animate-pulse h-3 w-21 bg-gray-200 rounded col-span-2"></div>

                    </dd>
                </div>
                <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6" v-if="props.userdata.user_mobile">
                    <dt class="text-sm font-medium text-gray-500">Telefon</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                        <span v-if="props.userdata.user_mobile">{{ props.userdata.user_mobile }}</span>
                        <div v-else class="animate-pulse h-3 w-21 bg-gray-200 rounded col-span-2"></div>
                    </dd>
                </div>
                <!-- <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">About</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia proident. Irure nostrud pariatur mollit ad adipisicing reprehenderit deserunt qui eu.</dd>
                </div> -->
                <!-- <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">Attachments</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                        <ul role="list" class="divide-y divide-gray-200 rounded-md border border-gray-200">
                            <li class="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <span class="ml-2 w-0 flex-1 truncate">resume_back_end_developer.pdf</span>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>
                            <li class="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                                <div class="flex w-0 flex-1 items-center">
                                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <span class="ml-2 w-0 flex-1 truncate">coverletter_back_end_developer.pdf</span>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">Download</a>
                                </div>
                            </li>
                        </ul>
                    </dd>
                </div> -->
            </dl>
        </div>
    </div>
</template>

<script setup>
import { PaperClipIcon } from '@heroicons/vue/20/solid'
import { watch } from 'vue';

const props = defineProps({
    userdata: Object
})
watch(() => props.userdata, val => {


});

</script>