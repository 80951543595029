<template>
    <form class="space-y-8 divide-y divide-gray-200" @submit.prevent="SavePassword">
        <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div class="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
                <div>
                    <h3 class="text-lg font-medium leading-6 text-gray-900">Passwort</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Ändern Sie hier Ihr Passwort selbst oder lassen Sie sich ein neues <router-link to="/forgotpasswd">zuschicken</router-link>
                    </p>
                </div>
            </div>
        </div>

        <div class="space-y-6 sm:space-y-5">
            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="old_pw" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Altes Passwort</label>
                <div class="mt-1 sm:col-span-2 sm:mt-0">
                    <input type="password" name="old_pw" id="old_pw" required :disabled="disabled" v-model="state.formDataPW.old_pw" class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:max-w-xs sm:text-sm disabled:bg-gray-100" />
                </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="new_pw" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Neues Passwort</label>
                <div class="mt-1 sm:col-span-2 sm:mt-0">
                    <input type="password" name="new_pw" id="new_pw" required :disabled="disabled" v-model="state.formDataPW.new_pw" class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:max-w-xs sm:text-sm disabled:bg-gray-100" @keydown="handleSearchKeydown" @paste="handlePaste"  />
                   <div class="flex my-1 text-sm" v-if="pwscore >= 3">
                    <ShieldCheckIcon class="h-5 w-4 flex-none text-green-600" aria-hidden="true" />Passwortstärke ist ok
                    </div>
                     <div class="flex my-1 text-sm" v-if="state.formDataPW.new_pw.length > 0 && pwscore < 3">
                        <ShieldExclamationIcon class="h-5 w-4 flex-none text-red-600" aria-hidden="true" /> Achtung unsicheres Passwort
                    </div>

                </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="new_pw2" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Neues Passwort wiederholen</label>
                <div class="mt-1 sm:col-span-2 sm:mt-0">
                    <input type="password" name="new_pw2" id="new_pw2" required :disabled="disabled" v-model="state.formDataPW.new_pw2" class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:max-w-xs sm:text-sm disabled:bg-gray-100" />
                </div>
            </div>
        </div>

        <div class="pt-5">
            <div class="flex justify-end">
                <router-link to="/forgotpasswd" type="button" class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">Neues Passwort anfordern</router-link>
                <button type="submit" class="ml-3 inline-flex justify-center rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 disabled:bg-gray-100">
                    <LoadingSpinner size="4" color="fill-primary-600" v-if="state.spinner" background-dark="dark:text-gray-100"></LoadingSpinner> Neues Passwort setzen
                </button>
            </div>
        </div>
    </form>
    <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-start px-4 py-6 sm:items-start sm:p-6 z-10">
        <MainToast :mode="state.message.messagemode" v-if="state.message.show">{{ state.message.message }}</MainToast>
    </div>
</template>

<script setup>
import { onBeforeMount, ref, inject, reactive, watch } from 'vue';
import LoadingSpinner from '../UI/Loading-Spinner.vue';
import MainToast from '../UI/Main-Toast.vue';
import { GetUserData } from '../../var/user';
import { Message, ErrorLog } from '../../var/Message'
import axios from 'axios';
import Simplestore from '@/core/var/Simplestore';
import { useAuthStore } from '@/core/store/AuthStore';
import { ShieldExclamationIcon, ShieldCheckIcon } from '@heroicons/vue/24/outline';


const auth = useAuthStore();
const userdata = auth.getUserData;
const disabled = ref(false);
const pwscore = ref(0);

const state = reactive({
    formData: {},
    formDataPW: {new_pw:''},
    spinner: false,
    servererror: false,
    message: {
        show: false,
        message: 'Ein schwerer Fehler ist aufgetreten, bitte versuchen Sie es erneut',
        messagemode: 'error'
    },
});

const user_channel = ref(process.env.VUE_APP_NEURAXIS_CHANNEL_ID);


const handleSearchKeydown = (event) =>{
    pwscore.value = getPasswordScore(state.formDataPW.new_pw);
}

const handlePaste = (event) => {
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData.getData('text/plain');
    pwscore.value = getPasswordScore(pastedText);
}


// watch(state.formDataPW.new_pw, (newValue, oldValue) => {
//     console.log('Text field value changed from', oldValue, 'to', newValue)
// });

onBeforeMount(async () => {
    GetUserData({
        user_id: userdata.user_id,
        user_email: userdata.user_email
    }).then(res => {
        disabled.value = false;
        state.formData = res.data;
    });
});


const SavePassword = async () => {

    const payload =
    {
        "user_oldpw": state.formDataPW.old_pw,
        "user_newpw": state.formDataPW.new_pw,
        "user_newpw2": state.formDataPW.new_pw2,
        "user_id": userdata.user_id,
        "user_channel": user_channel.value
    }

    if (payload.user_oldpw && payload.user_newpw && payload.user_newpw2) {
        state.spinner = true;
        disabled.value = true;
        Simplestore.spinner = true;
        await axios
            .post("/dashboard/changepw", payload)
            .then((response) => {

                if (response.data === false) {
                    Message('Bei der Datenverarbeitung ist ein Fehler aufgetreten', 'error', state);
                    state.spinner = false;
                    Simplestore.spinner = false;
                    disabled.value = false;
                } else {
                    if (response.data.message && response.data.message === 'missmatch') {
                        Message('Hier stimmt etwas nicht. Entweder Ihr altes Passwort ist falsch, oder das neue stimmt nicht mit der Wiederholung überein', 'error', state);
                        state.spinner = false;
                        disabled.value = false;
                        Simplestore.spinner = false;
                    } else if (response.data.message && response.data.message === 'success') {
                        Message('Ihr Passwort wurde erneuert Foo', 'success', state);
                        state.spinner = false;
                        disabled.value = false;
                        Simplestore.spinner = false;
                    }
                }

            })
            .catch(function (error) {
                state.message.show = true;
                ErrorLog(error);
                window.setTimeout(() => {
                    state.spinner = false
                    state.message.show = false;
                    disabled.value = false;
                    Simplestore.spinner = false;
                }, 3000)
            })
    }
}


function getPasswordScore(password = '') {
    let score = 0;

    if (password.length == '') {
        score += 0;
    }

    // Add points for length
    if (password.length >= 8) {
        score += 1;
    }
    if (password.length >= 12) {
        score += 1;
    }

    // Add points for uppercase letters
    if (/[A-Z]/.test(password)) {
        score += 1;
    }

    // Add points for lowercase letters
    if (/[a-z]/.test(password)) {
        score += 1;
    }

    // Add points for numbers
    if (/\d/.test(password)) {
        score += 1;
    }

    // Add points for special characters
    if (/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
        score += 1;
    }

    // Subtract points for common words or patterns
    if (/password/i.test(password)) {
        score -= 1;
    }
    if (/1234/.test(password)) {
        score -= 1;
    }

    return score;
}


</script>