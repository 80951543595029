<template>
    <form id="otp-form" @submit.prevent="handleSubmit">
        <div class="flex items-center justify-center gap-3">
            <input v-for="(input, index) in inputfields" :key="index" type="text" class="w-10 h-10 text-center text-xl font-bold text-slate-900 bg-slate-100 border border-transparent hover:border-slate-200 appearance-none rounded p-2 outline-none focus:bg-white focus:border-primary-400 focus:ring-2 focus:ring-primary-100" pattern="\d*" maxlength="1" v-model="input.value"
                @input="handleInput(index)" @keydown="handleKeyDown($event, index)" @focus="handleFocus($event)" @paste="handlePaste($event)" :ref="(el) => (inputRefs[index] = el)" />
        </div>
    </form>
</template>

<script setup>
import { ref, nextTick, watch } from 'vue';

const inputfields = ref([
    { value: '' },
    { value: '' },
    { value: '' },
    { value: '' },
    { value: '' },
]);
const inputRefs = ref([]);

const handleInput = (index) => {
    if (inputfields.value[index].value) {
        if (index === inputfields.value.length - 1) {
            // Last input field, do nothing
            return;
        }
        inputRefs.value[index + 1].focus();
    }
};

const handleKeyDown = async (e, index) => {
    if (
        !/^[0-9]{1}$/.test(e.key) &&
        e.key !== 'Backspace' &&
        e.key !== 'Delete' &&
        e.key !== 'Tab' &&
        !e.metaKey
    ) {
        e.preventDefault();
    }

    if (e.key === 'Delete' || e.key === 'Backspace') {
        if (index > 0) {
            const newinputfields = [...inputfields.value];
            newinputfields[index - 1].value = '';
            inputfields.value = newinputfields;
            await nextTick();
            inputRefs.value[index - 1].focus();
        }
    }
};

const handleFocus = (e) => {
    e.target.select();
};

const handlePaste = (e) => {
    e.preventDefault();
    const text = e.clipboardData.getData('text');
    if (!new RegExp(`^[0-9]{${inputfields.value.length}}$`).test(text)) {
        return;
    }
    const digits = text.split('');
    const newinputfields = inputfields.value.map((input, index) => ({
        value: digits[index] || '',
    }));
    inputfields.value = newinputfields;
};

const emit = defineEmits(['submit']);

const handleSubmit = () => {
    console.log('Form submitted!');
    const otp = inputfields.value.map((input) => input.value).join('');
    emit('submit', otp);
};

watch(
    inputfields,
    (newInputFields) => {
        const otp = newInputFields.map((input) => input.value).join('');
        emit('submit', otp);
    },
    { deep: true }
);
</script>76897