<template>
    <Toast />

    <Sidebar v-model:visible="sidebar" header="User Info" class="p-sidebar-md" position="right">
        <div class="flex p-4">

            <div class="pt-8 sm:pt-10">
                <h3 class="text-xl font-medium leading-6 text-gray-900 text-left">Persönliche Informationen</h3>

                <div class="block my-2">
                    <img class="inline-block h-24 w-24 rounded-full" v-if="message.user_avatar" :src="message.user_avatar" alt="" />
                    <UserCircleIcon class="h-24 w-24 text-gray-300" aria-hidden="true" v-else />
                </div>

                <div class="space-y-6 sm:space-y-5">

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5" v-if="message.user_salutation">
                        <div class="block text-sm font-medium text-gray-700 sm:pt-2">Anrede</div>
                        <div class="block text-sm font-normal text-gray-700 sm:pt-2">{{ message.user_salutation.name }}</div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5" v-if="message.user_firstname">
                        <div class="block text-sm font-medium text-gray-700 sm:pt-2">Vorname</div>
                        <div class="block text-sm font-normal text-gray-700 sm:pt-2">{{ message.user_firstname }}</div>
                    </div>


                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5" v-if="message.user_lastname">
                        <div class="block text-sm font-medium text-gray-700 sm:pt-2">Nachname</div>
                        <div class="block text-sm font-normal text-gray-700 sm:pt-2">{{message.user_lastname }}</div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                        <div class="block text-sm font-medium text-gray-700 sm:pt-2">E-mail</div>
                        <div class="block text-sm font-normal text-gray-700 sm:pt-2">{{ message.user_email }}</div>
                    </div>
                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5" v-if="message.user_land">
                        <div class="block text-sm font-medium text-gray-700 sm:pt-2">Land</div>
                        <div class="block text-sm font-normal text-gray-700 sm:pt-2">{{ message.user_land }}</div>
                    </div>
                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5" v-if="message.user_roles">
                        <div class="block text-sm font-medium text-gray-700 sm:pt-2">Rolle</div>
                        <div class="block text-sm font-normal text-gray-700 sm:pt-2">{{ user_role }}</div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5" v-if="message.user_mobile && message.user_mobile_prefix">
                        <div class="block text-sm font-medium text-gray-700 sm:pt-2">Mobilnummer</div>
                        <div class="block text-sm font-normal text-gray-700 sm:pt-2">{{ message.user_mobile_prefix }}{{ message.user_mobile }} <i class="pi pi-sign-in cursor-pointer bg-primary" @click="send_login(message)"></i></div>
                    </div>

                </div>
            </div>

        </div>
    </Sidebar>
    <Sidebar v-model:visible="sidebar_edit" header="User Bearbeiten" class="p-sidebar-md" position="right">
        <UserdataForm :initialFormData="getEditData" @submit="save_changes" />
    </Sidebar>
    <!-- <ConfirmDialog>
        <template #message="slotProps">

            <div class="flex p-4">

                <div class="pt-8 sm:pt-10">
                    <h3 class="text-xl font-medium leading-6 text-gray-900 text-left">Persönliche Informationen</h3>

                    <div class="block my-2">
                        <img class="inline-block h-24 w-24 rounded-full" :src="slotProps.message.message.user_avatar" v-if="slotProps.message.message.user_avatar" alt="" />
                        <UserCircleIcon class="h-24 w-24 text-gray-300" aria-hidden="true" v-else />
                    </div>

                    <div class="space-y-6 sm:space-y-5">

                        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                            <div class="block text-sm font-medium text-gray-700 sm:pt-2">Vorname</div>
                            <div class="block text-sm font-normal text-gray-700 sm:pt-2">{{ slotProps.message.message.user_firstname }}</div>
                        </div>


                        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                            <div class="block text-sm font-medium text-gray-700 sm:pt-2">Nachname</div>
                            <div class="block text-sm font-normal text-gray-700 sm:pt-2">{{ slotProps.message.message.user_lastname }}</div>
                        </div>

                        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                            <div class="block text-sm font-medium text-gray-700 sm:pt-2">E-mail</div>
                            <div class="block text-sm font-normal text-gray-700 sm:pt-2">{{ slotProps.message.message.user_email }}</div>
                        </div>


                        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                            <div class="block text-sm font-medium text-gray-700 sm:pt-2">Telefon</div>
                            <div class="block text-sm font-normal text-gray-700 sm:pt-2">{{ slotProps.message.message.user_mobile }}</div>
                        </div>

                    </div>
                </div>

            </div>
        </template>
    </ConfirmDialog> -->

    <Toolbar>

        <template #start>
            <div class="inline sm:hidden">
                <div class="">
                    <SplitButton label="Neu" icon="pi pi-plus" @click="setNew" :model="items" />
                </div>
            </div>
            <div class="hidden sm:flex">
                <Button v-show="changes != 0" v-tooltip.bottom="'Änderungen zurücksetzen'" @click="reset_changes" class="mr-2 p-button-danger" icon="pi pi-refresh" />
                <!-- <Button v-show="changes > 0" @click="save_changes" v-tooltip.bottom="'Änderungen speichern'" :label="save_text" class="w-auto  p-button-success mx-2" icon="pi pi-save" /> -->
                <Button label="Benutzer einladen" icon="pi pi-plus" class="p-button" @click="inviteUserDialog = true" />
            </div>
        </template>


    </Toolbar>
    <Toolbar class="mt-2">
        <template #start>
            <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filter_dataset['global'].value" placeholder="Suche" style="width: 400px" class="mr-2" />
            </span>
        </template>
    </Toolbar>
    <!--------------------------------------------------->
    <!------------------ DataTable ---------------------->
    <!--------------------------------------------------->
    <DataTable class="mt-3" v-if="!loader" v-model:filters="filter_dataset" v-on:row-click="openUserCard" :value="users" :rows="20" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20, 50, 100]" @rowReorder="onusersRowReorder" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="{first} bis {last} von {totalRecords}">

        <Column field="user_firstname" header="Name">
            <template #body="slotProps">

                <div v-if="slotProps.data.user_firstname" class="flex items-center">

                    <span class="inline-block h-10 w-10 overflow-hidden rounded-full bg-gray-100" v-if="!slotProps.data.user_avatar">
                        <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                    </span>

                    <img class="inline-block h-10 w-10 rounded-full" :src="slotProps.data.user_avatar" alt="" v-else />
                    <span class="font-bold ml-3 name">{{ slotProps.data.user_firstname }} {{ slotProps.data.user_lastname }}</span>
                </div>

                <div v-if="!slotProps.data.user_firstname">
                    <Avatar icon="pi pi-envelope" class="mr-3 bg-orange-500 text-white" shape="circle" />
                    <span class="font-bold text-orange-500">Benutzer eingeladen</span>
                </div>
            </template>
        </Column>
        <Column field="user_lastname" header="Nachnamen" hidden></Column>
        <Column field="user_email" header="E-Mail"></Column>
        <Column field="user_roles" header="Rolle">
            <template #body="slotProps">
                <Chip :label="get_role_name(slotProps.data.user_roles[0])" icon='pi pi-users' class="chip-primary" />
            </template>
        </Column>
        <Column>
            <template #body="slotProps">
                <Button v-if="force_save == false" @click="delete_confirm(slotProps.data.user_email)" class="p-button-rounded p-button-text" v-tooltip.top="'Datensatz löschen'" icon="pi pi-trash" />
                <Button @click="openEdit(slotProps.data)" class="p-button-rounded p-button-text" v-tooltip.top="'User bearbeiten'" icon="pi pi-pencil" />
                <Button v-if="force_save == false && slotProps.data.user_mobile" class="p-button-rounded p-button-text" @click="send_login(slotProps.data)" v-tooltip.top="'Login Senden'" icon="pi pi-sign-in" />
            </template>
        </Column>
    </DataTable>

    <div class="flex items-center justify-center m-16" v-show="loader">
        <LoadingSpinner size="12"></LoadingSpinner>
    </div>


    <!------------------- Dialogs ----------------------->
    <Dialog v-model:visible="editDialog" header="Datensatz bearbeiten" :modal="true" class="w-auto md:w-3/4">
        <div class="flex flex-col md:flex-row">
            <div class="w-full p-3">
                <div class="my-2">
                    <label for="name">Frage</label>
                    <InputText v-model="category_name" id="name" class="w-full" />
                </div>
                <div class="my-2">
                    <Dropdown v-model="data_status" id="status" :options="status" optionLabel="label" optionValue="value" />
                </div>
            </div>
        </div>
        <div class="p-3">
            <Button label="Speichern" @click="save_dataset(data_index), editDialog = false" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
            <Button label="Abbrechen" @click="editDialog = false" class="w-auto mr-3 mb-1 p-button-danger" icon="pi pi-times" />
        </div>

    </Dialog>

    <!------------------ New ---------------------->
    <Dialog v-model:visible="newDialog" header="User hinzufügen" :modal="true" class="w-full mx-3 md:w-3/4">

        <div class="flex flex-col md:flex-row">
            <div class="w-full p-3">
                <div class="my-2">
                    <label for="name">Kategorie</label>
                    <InputText v-model="category_name" id="name" class="w-full" />
                </div>
            </div>
        </div>

        <div class="p-3">
            <Button :disabled="category_name == ''" label="Speichern" @click="new_dataset(), newDialog = false" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
            <Button label="Abbrechen" @click="newDialog = false" class="w-auto mr-3 mb-1 p-button-danger" icon="pi pi-times" />
        </div>

    </Dialog>


    <Dialog v-model:visible="chatGptDialog" :style="{ width: '80%' }" header="Inhalte mit AI erstellen" :modal="true" class="p-fluid">
        <ChatGPT :questions="chatGptQuestions" @chat-callback="update_text_content" />
    </Dialog>

    <!---------------------------------------------------->
    <!------------------ Invite User --------------------->
    <!---------------------------------------------------->
    <Dialog v-model:visible="inviteUserDialog" :style="{ width: '700px' }" header="Benutzer einladen" :modal="true" class="p-fluid z-5">
        <div class="">
            <div class="mt-3">
                <span class="p-float-label">
                    <InputText id="email" type="text" v-model="invite_email" />
                    <label for="email">E-Mail</label>
                </span>
            </div>
            <div class="flex flex-row py-3">
                <Button :disabled="invite_email_status ? false : true" label="Benutzer einladen" @click="inviteUser" class="w-10 mr-2 mb-1 p-button bg-primary-500" icon="pi pi-send" />
                <Button label="Abbrechen" @click="inviteUserDialog = false" class="w-10 mr-2 mb-1 p-button-danger" icon="pi pi-times" />
            </div>
        </div>
    </Dialog>
</template>

<script setup>
import { ref, onMounted, watch, inject } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { LoadRoles } from '@/core/var/user';
import Simplestore from '@/core/var/Simplestore';
import LoadingSpinner from '@/core/components/UI/Loading-Spinner.vue';
import { FilterMatchMode } from 'primevue/api'
import ChatGPT from '@/core/components/Components/Main-GPT.vue';
import { useCustomerStore } from '@/core/store/CustomerStore'
import { slugify } from '@/core/var/tools'
import { useConfirm } from "primevue/useconfirm";
import { PhotoIcon, UserCircleIcon } from '@heroicons/vue/24/solid'
import ConfirmPopup from 'primevue/confirmpopup';
import UserdataForm from '@/core/components/Forms/User-Data.vue';

const toast = useToast()
const SERVICE_URL = inject('SERVICE_URL');
//const team = ref([])
const users = ref()
const roles = ref()
const inviteUserDialog = ref(false)
const invite_email = ref()
const invite_email_status = ref(false)
const category_name = ref(null)
const data_status = ref(null)
const data_content = ref(null)

const chatGptQuestions = ref([])
const chatGptDialog = ref(false)
const changes = ref(0)
const save_text = ref("Änderungen speichern")
const editDialog = ref(false)
const newDialog = ref(false)
const sidebar = ref(false)
const message = ref(null)
const sidebar_edit = ref(false)
const user_role = ref(null)
// const imageUploadDialog = ref(false)
// const image_library = ref([])
// const edit_image = ref(false)
// const edit_avatar = ref(false)
// const data_workflow_id = ref(null)

const force_save = ref(false)
const loader = ref(false)
const disabled = ref()
const status = ref([
    { label: 'Aktiv', value: 'published' },
    { label: 'Inaktiv', value: 'draft' }
])
const filter_dataset = ref({ 'global': { value: null, matchMode: FilterMatchMode.CONTAINS } })
const confirm = useConfirm();

const getEditData = ref({})

const openEdit = (data) => {
    getEditData.value = data
    sidebar_edit.value = true
}

const openUserCard = async e => {

    user_role.value = get_role_name(e.data.user_roles[0])
    sidebar.value = true
    message.value = e.data
}

const delete_confirm = (user_email) => {

    confirm.require({
        message: `Soll dieser User (${user_email}) wirklich gelöscht werden?`,
        header: 'User entfernen',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja',
        rejectLabel: 'Nein',
        accept: () => {
            const apiUrl = process.env.VUE_APP_NEURAXIS_API_MAIN;

            try {
                axios.post(`${apiUrl}/dashboard/${endpoints.delete}`, { user_email: user_email })
                    .then(responses => {
                        if (responses.data.message == 'success') {
                            toast.add({ severity: 'success', summary: 'Erfolg', detail: 'User wurde entfernt', life: 3000 });
                        } else {
                            toast.add({ severity: 'error', summary: 'Fehler', detail: 'User konnte nicht gelöscht werden', life: 6000 });
                        }
                        bootstrap()
                    })
                    .catch(error => {
                        console.error(error);
                    });
            } catch (error) {
                console.error(error);
            }
        },
        reject: () => {

        }
    });
};

const slug = ref(useCustomerStore().getCustomer.customer);
const channel = ref(slug)
const module = '';

const endpoints = {
    get: `${module}get-settings-users`,
    save: `${module}save-settings-users`,
    delete: `${module}delete-user`,
    edit: `${module}edit-user`
}


onMounted(async () => {
    loader.value = true;
    await bootstrap();
    loader.value = false;
})


const bootstrap = async () => {

    const apiUrl = process.env.VUE_APP_NEURAXIS_API_MAIN;

    const rolesPromise = axios.get(`${apiUrl}/dashboard/get-settings-roles`);
    const usersPromise = axios.get(`${apiUrl}/dashboard/get-settings-users`);

    try {
        await Promise.all([rolesPromise, usersPromise])
            .then(responses => {
                roles.value = responses[0].data;
                users.value = responses[1].data;
            })
    } catch (error) {
        console.error(error);
    }
}



const items = ref([
    {
        label: save_text,
        icon: 'pi pi-refresh',
        disabled: changes.value > 0 ? false : true,
        command: () => {
            save_changes();
            //toast.add({ severity: 'success', summary: 'Updated', detail: 'Data Updated', life: 3000 });
        }
    },
    {
        label: 'Zurücksetzen',
        icon: 'pi pi-times',
        disabled: changes.value != 0 ? false : true,
        command: () => {
            reset_changes();
            //toast.add({ severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000 });
        }
    },
]);

watch(changes, res => {
    items.value[0].disabled = res > 0 ? false : true;
    items.value[1].disabled = res != 0 ? false : true;
});

watch(loader, res => {
    Simplestore.spinner = res;
    disabled.value = res;
});

const reset_changes = async () => {
    try {
        const response = await axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/dashboard/${endpoints.get}`);
        users.value = response.data;
        changes.value = 0;
        save_text.value = "Änderungen speichern";
        force_save.value = false;
    } catch (error) {
        console.error(error);
    }
};

const onusersRowReorder = (e) => {
    users.value = e.value
    changes.value = changes.value + 1
    save_text.value = "Speichern (" + changes.value + ")"
    force_save.value = true
    toast.add({ severity: 'success', summary: 'Reihenfolge geändert', detail: 'Die Reihenfolge wurde erfolgreich geändert', life: 3000 })
}

const get_status = (status_value) => {
    for (let i = 0; i < status.value.length; i++) {
        if (status.value[i].value == status_value) {
            if (status_value == "published") {
                return "Aktiv"
            }
            if (status_value == "draft") {
                return "Inaktiv"
            }
        }
    }
}


const setNew = () => {
    newDialog.value = true;
    category_name.value = '';
}

const save_dataset = (index) => {
    users.value[index]["name"] = category_name.value
    users.value[index]["status"] = data_status.value
    users.value[index]["value"] = slugify(category_name.value)
    incrementSaves();
}

const new_dataset = () => {

    const slug = slugify(category_name.value);
    const new_data = {
        "name": category_name.value,
        "value": slug,
        "status": "draft",
    }

    users.value.unshift(new_data)

    force_save.value = true
    incrementSaves();
}

const delete_dataset = (id) => {
    const newt = users.value.filter(dataset => dataset.user_email !== id);
    users.value = newt;
    incrementSaves();
}

const delete_user = (email) => {
    console.log('tbd: ' + email);
}


const incrementSaves = () => {
    changes.value = changes.value + 1
    save_text.value = "Speichern (" + changes.value + ")"
}


const save_changes = async (data) => {
    console.log(data)
    data.user_roles = [data.user_roles]
    try {
        await axios.post(
            `${process.env.VUE_APP_NEURAXIS_API_MAIN}/dashboard/${endpoints.edit}`,
            data
        );
        toast.add({
            severity: "success",
            summary: "Änderungen gespeichert",
            detail: "Die Änderungen wurden erfolgreich gespeichert",
            life: 3000
        });
        changes.value = 0;
        save_text.value = "Änderungen speichern";
        force_save.value = false;
        sidebar_edit.value = false;
        bootstrap()
    } catch (error) {
        console.error(error);
    }
}

const save_changes_ = async () => {
    try {
        await axios.post(
            `${process.env.VUE_APP_NEURAXIS_API_MAIN}/dashboard/${endpoints.save}`,
            users.value
        );
        toast.add({
            severity: "success",
            summary: "Änderungen gespeichert",
            detail: "Die Änderungen wurden erfolgreich gespeichert",
            life: 3000
        });
        changes.value = 0;
        save_text.value = "Änderungen speichern";
        force_save.value = false;
    } catch (error) {
        console.error(error);
    }
};

const GPT_Primer = (title) => {
    chatGptQuestions.value = [
        {
            question: `Erstelle einen FAQ-Antort auf die Frage zum Thema ${title}`,
            button_name: "Antwort automatisch erstellen",
        }
    ];
}

const update_text_content = res => {
    data_content.value = data_content.value + res.chatGptAnswers;
    chatGptDialog.value = false;
}

const get_role_name = (role_id) => {
    return roles.value.find(role => role.level == role_id)?.name
}
watch(invite_email, () => {
    invite_email_status.value = invite_email.value.match(/.*@.*\..*/) !== null ? true : false
})

// @deprecated
const invite_user = () => {
    if (check_email_exists(invite_email.value.replace(/\s+/g, ''))) {
        toast.add({ severity: 'error', summary: 'Fehler', detail: 'Benutzer existiert bereits', life: 3000 })
    }
    else {
        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/dashboard/invite-email', { email: invite_email.value.replace(/\s+/g, ''), channel_name: channel.value })
            .then(response => {
                if (response.data.status === "success") {
                    toast.add({ severity: 'success', summary: 'Erfolg', detail: 'Benutzer erfolgreich eingeladen', life: 3000 })
                    bootstrap()
                    inviteUserDialog.value = false
                } else {
                    toast.add({ severity: 'error', summary: 'Fehler', detail: 'Benutzer konnte nicht eingeladen werden', life: 3000 })
                }
                loader.value = false
            })
    }
}

const inviteUser = async () => {
    const email = invite_email.value.trim();

    if (check_email_exists(email)) {
        toast.add({
            severity: 'error',
            summary: 'Fehler',
            detail: 'Benutzer existiert bereits',
            life: 3000
        });
    } else {
        try {
            const { data } = await axios.post(
                `${process.env.VUE_APP_NEURAXIS_API_MAIN}/dashboard/invite-email`,
                { email, channel_name: channel.value }
            );

            if (data.message === "success") {
                toast.add({
                    severity: 'success',
                    summary: 'Erfolg',
                    detail: 'Benutzer erfolgreich eingeladen',
                    life: 3000
                });
                bootstrap();
                inviteUserDialog.value = false;
            } else {
                toast.add({
                    severity: 'error',
                    summary: 'Fehler',
                    detail: 'Benutzer konnte nicht eingeladen werden',
                    life: 3000
                });
            }
            loader.value = false;
        } catch (error) {
            console.error('There was an error inviting the user:', error);
        }
    }
}


const check_email_exists = (email) => {
    return users.value.some(function (el) {
        return el.user_email === email
    })
}


const send_login = async (data) => {

    const number = `${data.user_mobile_prefix}${data.user_mobile}`
    data.channel = channel.value;
    const dashboard_adress = `https://${data.channel}.snpy.ch/snappy-sms`
    data.user_salutation.name = !data.user_salutation.name ? 'Herr' : data.user_salutation.name

    console.log(data)

    confirm.require({
        message: `Willst du ${data.user_salutation.name} ${data.user_firstname} ${data.user_lastname}, ${number} Zugang per SMS Login gewähren?`,
        header: 'Zugang gewähren',
        rejectLabel: 'Nein',
        acceptLabel: 'Ja',
        acceptClass: 'p-button-success',
        icon: 'pi pi-sign-in',
        accept: async () => {
            const res = await axios.post(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/dashboard/send-login-sms`, { number: number, dashboard_url: dashboard_adress, metadata: data });

            if (res.data.error) {
                toast.add({
                    severity: "error",
                    summary: "Fehler",
                    detail: "Es ist ein Fehler aufgetreten" + res.data.error,
                    life: 3000
                });
            } else {
                toast.add({
                    severity: "success",
                    summary: "SMS gesendet",
                    detail: "Die SMS wurde erfolgreich gesendet",
                    life: 3000
                });
            }
        }
    })
}

</script>
