<script setup>
import Logout from '../var/Logout';
import { useAuthStore } from '@/core/store/AuthStore';
import CoreRouter from '../router/router';
import { onMounted } from 'vue';

const auth = useAuthStore();

onMounted(() =>{
    auth.Logout();
    Logout();
    CoreRouter.push('/');
});
</script>
<template>
    <div></div>
</template>