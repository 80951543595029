<template>
    <div class="p-fluid space-y-6 pt-8 sm:space-y-5 sm:pt-10">
        <div class="space-y-6 sm:space-y-5">

            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="firstname" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Anrede</label>
                <div class="mt-1 sm:col-span-2 sm:mt-0">
                    <Dropdown v-model="formData.user_salutation" :options="remote_salutations" optionLabel="name" :disabled="disabled" id="user_salutation" class="w-full" required />
                </div>
            </div>

            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="firstname" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Vorname</label>
                <div class="mt-1 sm:col-span-2 sm:mt-0">
                    <InputText id="firstname" v-model="formData.user_firstname" :disabled="disabled" class="w-full max-w-lg" />
                </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="last-name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Nachname</label>
                <div class="mt-1 sm:col-span-2 sm:mt-0">
                    <InputText id="last-name" v-model="formData.user_lastname" :disabled="disabled" class="w-full max-w-lg" />
                </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="email" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">E-Mail</label>
                <div class="mt-1 sm:col-span-2 sm:mt-0">
                    <InputText id="user_email" v-model="formData.user_email" :disabled="disabled" class="w-full max-w-lg" />
                </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="user_street" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Strasse</label>
                <div class="mt-1 sm:col-span-2 sm:mt-0">
                    <InputText id="user_street" v-model="formData.user_street" :disabled="disabled" class="w-full max-w-lg" />
                </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="user_plz" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">PLZ</label>
                <div class="mt-1 sm:col-span-2 sm:mt-0">
                    <InputText id="user_plz" v-model="formData.user_plz" :disabled="disabled" class="w-full max-w-lg" />
                </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="user_city" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Stadt</label>
                <div class="mt-1 sm:col-span-2 sm:mt-0">
                    <InputText id="user_city" v-model="formData.user_city" :disabled="disabled" class="w-full max-w-lg" />
                </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="user_land" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Land</label>
                <div class="mt-1 sm:col-span-2 sm:mt-0">
                    <Dropdown id="user_land" v-model="selectedCountryOption" :disabled="disabled" :options="countries" optionLabel="name" class="w-full max-w-lg" />
                </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="user_roles" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Role</label>
                <div class="mt-1 sm:col-span-2 sm:mt-0">
                    <Dropdown id="user_roles" v-model="selectedRoleOption" :disabled="disabled" :options="avail_user_roles" optionLabel="name" optionValue="level" class="w-full max-w-lg"  />
                </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="user_mobile" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Mobilnummer</label>
                <div class="mt-1 sm:col-span-2 sm:mt-0">
                    <!-- <InputText id="user_mobile" v-model="formData.user_mobile" :disabled="disabled" class="w-full max-w-lg" /> -->
                    <div class="">
                        <!-- Mobile -->
                        <div class="mb-4">
                            <div class="flex flex-wrap">
                                <div class="w-1/4 pr-2">
                                    <Dropdown v-model="formData.user_mobile_prefix" :options="mobile_codes" optionLabel="name" option-value="name" class="w-full" />
                                </div>
                                <div class="w-3/4">
                                    <InputText v-model="formData.user_mobile" id="mobile" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="user_password" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Passwort</label>
                <div class="mt-1 sm:col-span-2 sm:mt-0">
                    <Password id="user_password" v-model="formData.user_password" :disabled="disabled" class="" toggleMask promptLabel="Wählen Sie ein Passwort" weakLabel="Sehr einfach" mediumLabel="Durchschnittliche Komplexität" strongLabel="Komplexes Passwort" />
                </div>
            </div>
            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="user_password_repeat" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Passwort wiederholen</label>
                <div class="mt-1 sm:col-span-2 sm:mt-0 w-full">
                    <Password id="user_password_repeat" v-model="formData.user_password_repeat" :disabled="disabled" class="" toggleMask promptLabel="Wählen Sie ein Passwort" weakLabel="Sehr einfach" mediumLabel="Durchschnittliche Komplexität" strongLabel="Komplexes Passwort" />
                </div>
            </div>

        </div>
        <div class="w-36">
            <Button @click="submitForm" label="Speichern" />
        </div>

    </div>

</template>

<script setup>
import { ref, onBeforeMount, computed } from 'vue';
import { LoadRoles } from '@/core/var/user';
import getAuth from '@/core/var/auth';
import Simplestore from '@/core/var/Simplestore';
import { useAuthStore } from '@/core/store/AuthStore';
import Password from 'primevue/password';
import axios from 'axios';

const auth = useAuthStore();
const userdata = auth.getUserData;
const remote_salutations = ref([]);
const selectedCountryOption = ref(null);
const selectedRoleOption = ref(null);

const props = defineProps({
    initialFormData: {
        type: Object,
        required: true
    }
});

const formData = ref({ ...props.initialFormData });

const selectedCountry = computed(() => {
    return countries.find(country => country.name === props.initialFormData?.user_land);
});

const selectedRole = computed(() => {
    const initialRole = props.initialFormData?.user_roles?.[0];
    if (avail_user_roles.value.length > 0) {
        const res = avail_user_roles.value.find(role => role.level === initialRole);
        return res.level;
    }
    return null;
});

// const updateSelectedCountry = (newValue) => {
//     selectedCountryOption.value = newValue;
// };

// const updateSelectedRole = (newValue) => {
//     selectedRoleOption.value = newValue;
// };

// const message = ref({
//     show: true,
//     message: 'Ein schwerer Fehler ist aufgetreten, bitte versuchen Sie es erneut',
//     messagemode: 'error'
// });

const mobile_codes = ref([
    { name: '+41' },
    { name: '+49' },
    { name: '+33' },
    { name: '+39' },
    { name: '+43' },
    { name: '+44' },
    { name: '+45' },
]);

const countries = [
    { name: 'Schweiz', code: 'CH' },
    { name: 'Deutschland', code: 'DE' },
    { name: 'Österreich', code: 'AT' },
    { name: 'Frankreich', code: 'FR' },
    { name: 'Italien', code: 'IT' },
    { name: 'Spanien', code: 'ES' },
    { name: 'Niederlande', code: 'NL' },
    { name: 'Belgien', code: 'BE' },
    { name: 'Luxemburg', code: 'LU' },
    { name: 'Liechtenstein', code: 'LI' },
    { name: 'Vereinigtes Königreich', code: 'GB' },
    { name: 'Irland', code: 'IE' },
    { name: 'Dänemark', code: 'DK' },
    { name: 'Norwegen', code: 'NO' },
    { name: 'Schweden', code: 'SE' },
    { name: 'Finnland', code: 'FI' },
    { name: 'Polen', code: 'PL' },
    { name: 'Tschechien', code: 'CZ' },
    { name: 'Slowakei', code: 'SK' },
    { name: 'Ungarn', code: 'HU' },
    { name: 'Slowenien', code: 'SI' },
    { name: 'Kroatien', code: 'HR' },
    { name: 'Bosnien und Herzegowina', code: 'BA' },
    { name: 'Serbien', code: 'RS' },
    { name: 'Montenegro', code: 'ME' },
    { name: 'Albanien', code: 'AL' },
    { name: 'Mazedonien', code: 'MK' },
    { name: 'Griechenland', code: 'GR' },
    { name: 'Türkei', code: 'TR' },
    { name: 'Zypern', code: 'CY' },
    { name: 'Malta', code: 'MT' },
    { name: 'Island', code: 'IS' },
    { name: 'Grönland', code: 'GL' },
    { name: 'Färöer', code: 'FO' },
    { name: 'Estland', code: 'EE' }
];

const avail_user_roles = ref([]);

onBeforeMount(async () => {
    Simplestore.spinner = true;
    disabled.value = true;

    try {
        const [authResult, rolesResult, dataSalutations] = await Promise.all([
            getAuth(),
            LoadRoles(auth.getUserRole),
            axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/dashboard/bexio-salutations`)
        ]);

        remote_salutations.value = dataSalutations.data;
        avail_user_roles.value = rolesResult;

        selectedCountryOption.value = selectedCountry.value;
        selectedRoleOption.value = selectedRole.value; 

        formData.value.user_password = ''
        formData.value.user_password_repeat = ''

    } catch (error) {
        console.error('Error loading data:', error);
    } finally {
        Simplestore.spinner = false;
        disabled.value = false;
    }
});

const disabled = ref(false);

const emit = defineEmits(['submit']);

const submitForm = () => {



    if (!formData.value.user_password) {
        delete formData.value.user_password;
    }

    if (!formData.value.user_password_repeat) {
        delete formData.value.user_password_repeat;
    }
    console.log(formData.value.user_password, formData.value.user_password_repeat);
    formData.value.user_land = selectedCountryOption.value?.name || '';
    formData.value.user_roles = selectedRoleOption.value;
    console.log('submitting form', formData.value);
    emit('submit', formData.value);
};
</script>